import {pick} from "lodash";
import {call, put, take, select, takeLatest, takeLeading} from "redux-saga/effects";
import log, {serializeError} from "@atg-shared/log";
import {PageAnalytics} from "@atg-shared/analytics";
import Features, {personalPush} from "@atg-shared/client-features";
import * as Storage from "@atg-shared/storage";
import * as Time from "@atg-shared/server-time";
import {Paths} from "@atg-tillsammans-shared/navigation";
import * as AuthActions from "@atg-shared/auth/domain/authActions";
import * as AuthSelectors from "@atg-shared/auth/domain/authSelectors";
import * as AccessTokenActions from "@atg-shared/auth/domain/accessTokenActions";
import type {AtgResponse, AtgRequestError} from "@atg-shared/fetch-types";
import browserHistory from "atg-history";
import {
    CONTEXT_HORSE,
    CONTEXT_SPORT,
    CONTEXT_CASINO,
    CONTEXT_TILLSAMMANS,
    CONTEXT_SHARES,
    CONTEXT_HARRY_BOY,
} from "@atg-global-shared/verticals";
import type {Solace} from "@atg-frame-shared/push";
import {subscribe} from "@atg-frame-shared/push";
import {getClientName, getRouteName} from "@atg-frame-shared/push-saga/helpers";
import type {ReconnectedAction} from "@atg-frame-shared/push-saga/domain/pushActions";
import {RECONNECTED} from "@atg-frame-shared/push-saga/domain/pushActions";
import {getSolaceClient} from "@atg-frame-shared/push-saga/domain/pushSaga";
import {
    FETCH_USER,
    SET_SHOW_BALANCE,
    FETCH_BALANCE,
    LOGOUT_USER,
    RECEIVE_USER,
    LOGIN_FINISHED,
    FETCH_USER_IF_AUTHORIZED,
    LOGOUT_FINALIZE,
    LOGOUT_FINISHED,
    REDUCE_SCOPE,
} from "./userActionTypes";
import type {
    SetShowBalanceAction,
    FetchBalanceAction,
    ReceiveUserAction,
    LogoutUserAction,
    FinalizeLogoutAction,
    LoginFinishedAction,
} from "./userActions";
import {
    receiveUserFailed,
    receiveUser,
    receiveBalance,
    receiveBalanceError,
    updateShowBalance,
    USER_LOCAL_ATTRIBUTES,
    logoutFinished,
    logoutInitiated,
} from "./userActions";
import * as UserAPI from "./userApi";
import * as UserSelectors from "./userSelectors";
import type {
    AuthorizedUser,
    FetchBalanceResponse,
    User,
    UserResponse,
} from "./user.types";
import {USER_STORAGE_KEY} from "./index";

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function userCallback() {}

export function* fetchUserFlow() {
    try {
        const {data: userResponse}: UserResponse = yield call(UserAPI.fetchUser);
        if (!userResponse) return;
        yield put(receiveUser(userResponse));
    } catch (error: unknown) {
        yield call(log.error, "User fetch", {error: serializeError(error)});
        yield call(receiveUserFailed, error as AtgRequestError);
    }
}

export function* fetchUserIfAuthorized() {
    yield put(AuthActions.checkAuth(false));
    yield take([AuthActions.AUTH_CHECK_RESPONSE, AuthActions.AUTH_SET_ROLES]);

    const isAuthenticated: boolean = yield select(AuthSelectors.isNormalLogin);

    if (isAuthenticated) {
        yield call(fetchUserFlow);
    }
}

export function* persistBalanceToUser(balance: {amount: number; timestamp: string}) {
    const user: AuthorizedUser = yield select(UserSelectors.getUser);
    let userAttributesToPersist = pick(user, USER_LOCAL_ATTRIBUTES);
    userAttributesToPersist = {
        ...userAttributesToPersist,
        balance,
    };
    yield call(
        Storage.setItem,
        USER_STORAGE_KEY,
        JSON.stringify(userAttributesToPersist),
    );
}

export function* fetchBalanceFlow(action: FetchBalanceAction | SetShowBalanceAction) {
    let balanceResponse: AtgResponse<FetchBalanceResponse>;
    const fetchBalanceOptions = action.type === FETCH_BALANCE ? action.payload : {};
    try {
        balanceResponse = yield call(UserAPI.fetchBalance, fetchBalanceOptions);
    } catch (error: unknown) {
        yield put(receiveBalanceError());
        yield call(log.error, "Balance fetch", {error: serializeError(error)});
        return;
    }

    if (balanceResponse) {
        const date = new Date();
        const balanceData = {
            amount: balanceResponse.data.balance,
            timestamp: date.toISOString(),
        };
        yield put(receiveBalance(balanceData));
        yield call(persistBalanceToUser, balanceData);
    }

    if (action.type === SET_SHOW_BALANCE) {
        yield put(updateShowBalance(action.payload.status));
    }
}

export function* persistUserFlow(
    action: ReceiveUserAction | LoginFinishedAction | SetShowBalanceAction,
) {
    const user: User = yield select(UserSelectors.getUser);
    let userAttributesToPersist = pick(user, USER_LOCAL_ATTRIBUTES);

    if (action.type === SET_SHOW_BALANCE) {
        userAttributesToPersist = {
            ...userAttributesToPersist,
            showBalance: action.payload.status,
        };
    }

    yield call(
        Storage.setItem,
        USER_STORAGE_KEY,
        JSON.stringify(userAttributesToPersist),
    );
}

export function* toggleShowBalanceFlow(action: SetShowBalanceAction) {
    if (action.payload.status === false) {
        yield put(updateShowBalance(action.payload.status));
        return;
    }

    const isNormalLogin: boolean = yield select(AuthSelectors.isNormalLogin);

    if (!isNormalLogin) {
        yield put(updateShowBalance(action.payload.status));
        return;
    }

    yield call(fetchBalanceFlow, action);
}

export function* logoutFlow(action: LogoutUserAction) {
    try {
        yield put(logoutInitiated());

        yield call(UserAPI.logout, action.payload);

        yield put(AccessTokenActions.resetAccessToken());

        const pageDate: string = yield call(Time.serverDate);

        yield call(PageAnalytics.view, ["konto", "utloggning"], pageDate);
    } catch (e: unknown) {
        // TODO: this flow fails in the app, investigate why
        log.error("LogoutFlow: Unable to destroy access token", {
            error: serializeError(e as Error),
        });
    }
}

export function* reduceScopeFlow() {
    try {
        const response: AtgResponse<UserAPI.ReduceScopeResponse> = yield call(
            UserAPI.reduceScope,
        );
        yield put(AccessTokenActions.reduceAccessToken(response.data));
    } catch (e: unknown) {
        // disregard the error
    }
}

export function* finalizeLogout(action: FinalizeLogoutAction | LogoutUserAction) {
    const {soft = false, vertical} = action.payload;
    yield put(logoutFinished());

    if (!soft) {
        switch (vertical) {
            case CONTEXT_SPORT:
                yield call(browserHistory.replace, "/sport");
                break;
            case CONTEXT_HORSE:
                yield call(browserHistory.replace, "/");
                break;
            case CONTEXT_CASINO:
                yield call(browserHistory.replace, "/casino");
                break;
            case CONTEXT_HARRY_BOY:
                yield call(browserHistory.replace, "/harryboy");
                break;
            case CONTEXT_SHARES:
                yield call(browserHistory.replace, "/andelar");
                break;
            case CONTEXT_TILLSAMMANS:
                yield call(browserHistory.replace, Paths.TILLSAMMANS_ROOT_PATH);
                break;
            default:
                break;
        }
    }
}

export function* persistLogoutUser() {
    const userFromState: User = yield select(UserSelectors.getUser);
    yield call(Storage.setItem, USER_STORAGE_KEY, JSON.stringify(userFromState));
}

export function* subscribeUserToPush(): any {
    const isAuthenticated: boolean = yield select(UserSelectors.isLoggedIn);
    if (!isAuthenticated) return;

    const client: Solace = yield call(getSolaceClient);

    try {
        yield call(UserAPI.subscribeUser, getClientName(client), getRouteName(client));
    } catch (e: unknown) {
        // do nothing
    }

    const topic = "user/>";
    const unsubscribe: () => void = yield call(
        subscribe,
        topic,
        userCallback,
        false,
        true,
    );
    const reconnectAction: ReconnectedAction = yield take([LOGOUT_USER, RECONNECTED]);

    yield call(unsubscribe);

    if (reconnectAction.type === RECONNECTED) {
        yield call(subscribeUserToPush);
    }
}

export default function* userSaga() {
    yield takeLatest(FETCH_USER, fetchUserFlow);
    yield takeLatest(FETCH_USER_IF_AUTHORIZED, fetchUserIfAuthorized);
    yield takeLatest(SET_SHOW_BALANCE, toggleShowBalanceFlow);
    yield takeLatest(LOGOUT_USER, logoutFlow);
    yield takeLatest(REDUCE_SCOPE, reduceScopeFlow);
    yield takeLatest(LOGOUT_FINALIZE, finalizeLogout);
    // exclusion for tillsammans since we have our own state persister
    yield takeLatest([RECEIVE_USER, LOGIN_FINISHED, SET_SHOW_BALANCE], persistUserFlow);
    yield takeLatest(LOGOUT_FINISHED, persistLogoutUser);
    if (Features.isEnabled(personalPush)) {
        yield takeLeading([LOGIN_FINISHED, RECEIVE_USER], subscribeUserToPush);
    }
    yield takeLatest(FETCH_BALANCE, fetchBalanceFlow);
}
