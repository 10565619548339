import {css} from "@emotion/react";
import {
    root as LoadingIndicatorRoot,
    rect1 as LoadingIndicatorRect1,
    rect2 as LoadingIndicatorRect2,
    rect3 as LoadingIndicatorRect3,
    rect4 as LoadingIndicatorRect4,
    rect5 as LoadingIndicatorRect5,
    invertedRect1 as LoadingIndicatorInvertedRect1,
    invertedRect2 as LoadingIndicatorInvertedRect2,
    invertedRect3 as LoadingIndicatorInvertedRect3,
    invertedRect4 as LoadingIndicatorInvertedRect4,
    invertedRect5 as LoadingIndicatorInvertedRect5,
} from "./LoadingIndicator.styles";

export const invertedRect1 = css`
    ${LoadingIndicatorInvertedRect1};
`;

export const invertedRect2 = css`
    ${LoadingIndicatorInvertedRect2};
`;

export const invertedRect3 = css`
    ${LoadingIndicatorInvertedRect3};
`;

export const invertedRect4 = css`
    ${LoadingIndicatorInvertedRect4};
`;

export const invertedRect5 = css`
    ${LoadingIndicatorInvertedRect5};
`;

export const overlay = css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 6;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
`;

export const rect1 = css`
    ${LoadingIndicatorRect1};
`;

export const rect2 = css`
    ${LoadingIndicatorRect2};
`;

export const rect3 = css`
    ${LoadingIndicatorRect3};
`;

export const rect4 = css`
    ${LoadingIndicatorRect4};
`;

export const rect5 = css`
    ${LoadingIndicatorRect5};
`;

export const root = css`
    ${LoadingIndicatorRoot};
`;
