import * as React from "react";
import * as Redux from "react-redux";
import LoadingIndicator from "atg-ui-components/components/LoadingIndicator";

type ComponentModule<InnerProps, Store> = {
    default: React.ComponentType<InnerProps>;
    init?: (store: Store) => void;
};

type Options<Store> = {
    placeholder?: React.ReactNode;

    delay?: number;
    /**
     * The hook used for getting the reference to current store. The return value of this hook will be passed to the `init` function of the loaded component.
     *
     * **Important: This hook must be stable and return same store reference on every render.**
     * @default Redux.useStore
     */
    useStoreForInitHook?: () => Store;
};

/**
 * Creates a lazy component wrapped in React.Suspense that can be used to load a component chunk asynchronously.
 * @param loader - A function that returns a promise that resolves to a module containing the component to be loaded.
 * @param options  - Options for configuring the lazy loader.
 * @returns a suspended component that will load the component chunk asynchronously.
 */
export function createLazyComponentLoader<IP, S>(
    loader: () => Promise<ComponentModule<IP, S>>,
    options: Options<S> = {},
) {
    let loaderPromise: Promise<ComponentModule<IP, S>>;
    let hasInitStore = false;

    const {
        placeholder = <LoadingIndicator inverted />,
        delay = 0,
        useStoreForInitHook = Redux.useStore,
    } = options;

    const LazyComponent = React.lazy(
        () =>
            new Promise<ComponentModule<IP, S>>((resolve, reject) => {
                loaderPromise = loader().then((module) => {
                    const {init: moduleInit} = module;
                    // if the module has an init function and it hasn't been called yet, we return a wrapper that calls the init function before resolving the outer Promise
                    // resolving the outer Promise after calling init prevents a race-condition where the lazy loaded component is rendered before the store has been initialized
                    if (moduleInit && !hasInitStore) {
                        return {
                            ...module,
                            init: (...args) => {
                                if (!hasInitStore) {
                                    moduleInit(...args);
                                    hasInitStore = true;
                                }
                                // resolve the outer Promise after the init function has been called, so the suspended component can render
                                resolve(module);
                            },
                        };
                    }
                    // if the module doesn't have an init function or it has already been called, we resolve the outer Promise immediately
                    resolve(module);
                    return module;
                });

                loaderPromise.catch(reject);
            }),
    );

    return function LazyComponentLoader(props: IP) {
        const store = useStoreForInitHook();
        React.useEffect(() => {
            loaderPromise?.then((module) => {
                if (module.init) {
                    // @ts-expect-error
                    module.init(store);
                }
            });
        }, [store]);

        const [showFallback, setShowFallback] = React.useState(!delay);

        React.useEffect(() => {
            if (delay > 0) {
                const timeout = setTimeout(() => {
                    setShowFallback(true);
                }, delay);

                return () => clearTimeout(timeout);
            }
            return undefined;
        }, []);

        return (
            <React.Suspense fallback={showFallback ? placeholder : null}>
                {/* @ts-expect-error */}
                <LazyComponent
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...props}
                />
            </React.Suspense>
        );
    };
}
