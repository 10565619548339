import {includes, isEmpty} from "lodash";
import {fetchSelectors} from "@atg-shared/fetch-redux";
import type {IDToken, ACR} from "./accessTokenActions";
import {ACR_USERNAME_PASSWORD} from "./accessTokenConstants";
import {LOGIN_NORMAL, LOGIN_COOKIE} from "./authRoles";
import type {MemberFlowOptions} from "./authActions";
import type {State, GlobalAccessTokenState} from "./index";

// 40 min
const BETTING_USER_TIME_LIMIT = 1000 * 60 * 40;

const getTokenState = (state: GlobalAccessTokenState) => state.accessToken.token;

export const isLoading = (state: State) => state.auth.loading;
export const getError = (state: State) => state.auth.error;
export const getData = (state: State) => state.auth.data;
export const getRoles = (state: State) => state.auth?.data?.roles;
export const getMemberId = (state: State): number | null =>
    state.auth?.data?.memberId ?? null;
export const hasRole = (state: State, role: string | null | undefined): boolean => {
    if (!role) return false;

    const roles = getRoles(state);
    if (isEmpty(roles)) return false;

    return includes(roles, role);
};

export const getMemberFlowOptions = (
    state: State,
): MemberFlowOptions | null | undefined => state.auth?.memberFlowOptions;

// Note that there might be situations when this returns true but login has actually expired.
// You most probably want to use checkAuth, fetchAuthorized or NeedAuth to have a guarantee.
export const isNormalLogin = (state: State): boolean => hasRole(state, LOGIN_NORMAL);

// Note that there might be situations when this is true but login has actually expired
export const isCookieLogin = (state: State): boolean => hasRole(state, LOGIN_COOKIE);

/**
 * This function will check if the user has a role saved, either "normal" ("betting user" scope) or "cookie" ("general user" scope, sometimes also referred to as "long-time logged in" user).
 * Note that it will return "true" even for a user in "general user" scope, not only "betting user"
 *
 * If it returns "false", it is reliable - user is not logged in
 *
 * If it returns "true", it is not reliable - user has logged in at some point with a role assigned. Alternatively an authModalFlow saga (e.g. after checkAuth() action) was run and set the role. However it is possible that this was done long ago, and user does not actually have a valid access token.
 * This function will return correct result directly after authModalFlow though (if after checkAuth action or rendering a component with NeedAuth wrapper)
 *
 * Main difference from UserSelectors.isLoggedIn is that it will "expire" sooner. And is more reliable in conjunction with checkAuth/authModalFlow)
 *
 * See function fetchAuthorized that can make a request and also trigger login prompt if the request is unauthorized
 *
 * More documentation on our auth flow - https://developer.atg.se/frontend/authentication/authentication.html
 *
 * TODO: a more proper name for this function could be wasLoggedInAfterLastAuthCheck, since there is no guarantee that one still is logged in.
 */
export const isLoggedIn = (state: State): boolean =>
    isNormalLogin(state) || isCookieLogin(state);

// Bit confusing naming; isGeneralUser = user set to GENERAL_USER scope (sometimes referred as "long time logged in", reduced scope, login cookie)
export const isGeneralUser = (state: State): boolean => isCookieLogin(state);

export const getAccessToken = (state: GlobalAccessTokenState) =>
    getTokenState(state).accessToken;

export const getIDToken = (state: GlobalAccessTokenState): IDToken | null | undefined =>
    getTokenState(state).idToken;

export const getAuthenticationType = (
    state: GlobalAccessTokenState,
): ACR | null | undefined => {
    const idToken = getIDToken(state);
    return idToken ? idToken.acr : null;
};
export const hasAccessToken = (state: GlobalAccessTokenState): boolean =>
    getAccessToken(state) !== null;

export const getTokenLoadingState = (state: GlobalAccessTokenState) =>
    fetchSelectors.getLoadingState(getTokenState(state));

export const {
    isLoading: isTokenLoading,
    isLoaded: isTokenLoaded,
    hasError: hasTokenError,
} = fetchSelectors.createSelectors<GlobalAccessTokenState>(getTokenState);

export const isLoggedInWithUsername = (state: GlobalAccessTokenState): boolean =>
    getAuthenticationType(state) === ACR_USERNAME_PASSWORD;

export const accessTokenTimestamp = (state: GlobalAccessTokenState) =>
    getTokenState(state).timestamp;

export const reducedScopeExpectedTimestamp = (state: GlobalAccessTokenState) => {
    const atTimestamp = accessTokenTimestamp(state);

    return atTimestamp ? atTimestamp + BETTING_USER_TIME_LIMIT : 0;
};

export const loginScope = (state: State) => {
    if (isNormalLogin(state)) return "betting";
    if (isGeneralUser(state)) return "general";
    return "logged out";
};
