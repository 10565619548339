import type {
    ContactOptionsResponse,
    EditContactOptionsPayload,
} from "@atg-payment-shared/account-settings-types";
import {fetchAuthorized} from "@atg-shared/auth";
import {RCS_SERVICE_URL} from "@atg-shared/service-url";

export const CONTACT_OPTIONS_URL = `${RCS_SERVICE_URL}/optins`;

export const fetchContactOptions = () =>
    fetchAuthorized<ContactOptionsResponse>(CONTACT_OPTIONS_URL);

export const editContactOptions = (params: EditContactOptionsPayload) =>
    fetchAuthorized<ContactOptionsResponse>(CONTACT_OPTIONS_URL, {
        method: "POST",
        body: JSON.stringify(params),
    });
