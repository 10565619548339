import * as React from "react";
import {useSearchParams} from "react-router-dom";

export function useCookieSettingsSearchParams() {
    const [searchParams, setSearchParams] = useSearchParams();
    const match = searchParams.get("openCookieModal");

    React.useEffect(() => {
        if (match) {
            const event = new CustomEvent("openCookieModal");
            window.dispatchEvent(event);
            searchParams.delete("openCookieModal");
            setSearchParams(searchParams);
        }
    }, [match, searchParams, setSearchParams]);
}
