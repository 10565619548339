import root from "window-or-global";
import isMobileLib from "ismobilejs/esm";
import {isApp} from "@atg-shared/system";

// In React Native `navigator` exists but `navigator.userAgent` is undefined, which ismobilejs
// crashes on. Instead send in an "empty" (but truthy) string.
const isMobile = isMobileLib(navigator.userAgent || " ");

// navigator.platform is deprecated, need to find a replacement solution there
const platform = navigator.platform || "unknown";

export const isDevice = () =>
    isMobile.any || isApp || (platform === "MacIntel" && navigator.maxTouchPoints > 1); // Include iPad iOS 13 in isDevice

// Any mobile android device
export const isAndroid = () => isMobile.android.device;

export const isAndroidPhone = () => isMobile.android.phone;

export const isAndroidTablet = () => isMobile.android.tablet;

export const isiOS = () =>
    isMobile.apple.device || (platform === "MacIntel" && navigator.maxTouchPoints > 1); // Include iPad iOS 13 in isIos

export const isiPad = () =>
    isMobile.apple.tablet || (platform === "MacIntel" && navigator.maxTouchPoints > 1); // Post iOS 13

// include iPod to stay consistent with how this function worked before we switched to `ismobilejs`
export const isiPhone = () => isMobile.apple.phone || isMobile.apple.ipod;

export const isTouchDevice = () => Boolean("ontouchstart" in root);

export const isTablet = () => isMobile.tablet;

// Keywords included in user agents from TV browsers
const tvAgents = ["Tizen", "Bravia"];

export const isTvBrowser = () =>
    tvAgents.some((userAgent) =>
        navigator.userAgent.toLowerCase().includes(userAgent.toLowerCase()),
    );

export const isRealIOSDevice = () => {
    if (/iPhone|iPod|iPad/.test(platform)) {
        return true;
    }

    if (
        platform === "MacIntel" &&
        navigator.maxTouchPoints > 1 &&
        /Mac OS X/.test(navigator.userAgent)
    ) {
        return true;
    }

    return false;
};
