import {call, put, select, delay} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import {DepositResponseStatus} from "@atg-payment-shared/deposit-utils";
import log, {serializeError} from "@atg-shared/log";
import type {
    CreditCardDepositOption,
    DepositOption,
} from "@atg-payment-shared/deposit-types";
import * as DepositActions from "../../actions/actions";
import * as DepositSelectors from "../../selectors/selectors";
import * as DepositApi from "../../api/api";
import {
    statusMessage,
    errorMessage,
    POLL_TIMEOUT_TICK,
    type DepositResponseError,
    logNotSuccessfulDeposit,
} from "../helpers/sagaHelpers";

export function* handleIFrameFlow(): SagaIterator {
    const selectedAmount = yield select(DepositSelectors.selectedDepositAmount);

    const selectedAmountAsNumber = Number(selectedAmount);

    if (!selectedAmountAsNumber) {
        log.error(
            `handleIFrameFlow saga called when selectedAmount can not be parsed to number: ${selectedAmount}`,
        );
        yield call(statusMessage, DepositResponseStatus.ERROR);
        return;
    }

    const option: DepositOption | null = yield select(DepositSelectors.selectedOption);

    if (!option) {
        log.error("handleIFrameFlow saga called when deposit option is null");
        yield call(statusMessage, DepositResponseStatus.ERROR);
        return;
    }

    // if this for Trustly and not credit card, we want the storeCard prop to be true because this enable a checkbox in the iFrame where the user can choose if they want to store their info or not
    const storeCard = (option as CreditCardDepositOption).storeCard ?? true;

    try {
        const response = yield call(DepositApi.depositApiCall, {
            amount: selectedAmountAsNumber,
            option,
            storeCard,
        });

        if (!response) {
            log.error("No response from BE when trying to initiate a deposit");
            yield call(statusMessage, DepositResponseStatus.ERROR);
            return;
        }

        const {redirectUrl, hostedViewUrl, orderId, amount} = response.data;
        yield put(
            DepositActions.startIframeDeposit(
                redirectUrl,
                hostedViewUrl,
                amount,
                orderId,
            ),
        );
    } catch (e: unknown) {
        const err = e as DepositResponseError;

        yield call(logNotSuccessfulDeposit, err);

        yield call(errorMessage, err.response);
    }
}

export function* finalizeDepositIframe(): SagaIterator {
    try {
        const orderId = yield select(DepositSelectors.orderIdForDepositIframe);
        const depositMethod: DepositOption | null = yield select(
            DepositSelectors.selectedOption,
        );

        if (!depositMethod) {
            log.error("finalizeDepositIframe was called when selectedOption is null");
            yield call(statusMessage, DepositResponseStatus.DELAYED);
            return;
        }

        const maxPollTime = 3000;
        let timeSpent = 0;
        let response = yield call(
            DepositApi.checkDepositStatus,
            orderId,
            depositMethod.id,
        );

        let {status} = response.data;

        // If finalizeDepositIframe is called and the status is still Initiated on the BE side, this means the user have aborted/cancelled the deposit inside Swedbank pay
        if (status === DepositResponseStatus.INITIATED) {
            yield call(statusMessage, DepositResponseStatus.CANCELLED);
            yield put(DepositActions.clearIframeDepositState());
            return;
        }

        while (status === DepositResponseStatus.IN_PROGRESS && timeSpent < maxPollTime) {
            yield delay(POLL_TIMEOUT_TICK);
            timeSpent += POLL_TIMEOUT_TICK;

            try {
                response = yield call(
                    DepositApi.checkDepositStatus,
                    orderId,
                    depositMethod.id,
                );
                ({status} = response.data);
            } catch (error: unknown) {
                const err = error as DepositResponseError;
                yield call(errorMessage, err.response);
            }
        }

        /**
         * payex is not always reliable and may return status "finished" too early,
         * even if the deposit has not been completed in our BE.
         * We poll the status to our BE until it is finished for real.
         *
         * If we exceed the max time, we cancel the deposit and emulate a
         * timeout failure. (Since we don't get a real timeout from BE)
         *
         * Note that this finalize function runs AFTER the payex iframe is
         * finished which means that this function runs on the initial deposit
         * screen. This may cause confusion both for us and our users.
         */
        if (status === DepositResponseStatus.IN_PROGRESS && timeSpent >= maxPollTime) {
            yield call(statusMessage, DepositResponseStatus.DELAYED);
        } else {
            yield put(DepositActions.depositFinalize());
            yield call(statusMessage, status);
        }

        yield put(DepositActions.clearIframeDepositState());
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        yield call(errorMessage, err.response);
    }
}

export function* cancelDepositInIframe(): SagaIterator {
    try {
        const orderId = yield select(DepositSelectors.orderIdForDepositIframe);
        const depositMethod: DepositOption | null = yield select(
            DepositSelectors.selectedOption,
        );

        if (depositMethod) {
            yield call(DepositApi.cancelInitiatedDeposit, orderId, depositMethod.id);
        } else {
            log.error(
                "cancelDepositInIframe was called when no depositMethod was stored in Redux",
            );
        }

        yield put(DepositActions.clearIframeDepositState());
        yield put(DepositActions.resetDepositIspending());
    } catch (e: unknown) {
        const err = e as DepositResponseError;
        log.error("cancelCardDepositInIframe: ", {error: serializeError(err)});
    }
}
