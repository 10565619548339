import * as React from "react";
import {Route, Routes} from "react-router-dom";
import {Global} from "@emotion/react";
import root from "window-or-global";
import {values} from "lodash/fp";
import {createLazyComponentLoader} from "@atg-shared/lazy";
import {GameTypes} from "@atg-horse-shared/game-types";
import {
    GlobalLoader as GlobalApp,
    HorseLoader as HorseApp,
    ShopLoader as ShopApp,
    CasinoLoader as CasinoApp,
    SportsbookLoader as SportsbookApp,
    PlayLoader as PlayApp,
    TillsammansLoader as TillsammansApp,
    AmlLoader as AmlApp,
    PaymentLoader as PaymentApp,
    RgLoader as RgApp,
    HorseRetailLoader as HorseRetailApp,
} from "@atg-frame-shared/bundle-loader";
import Features, {framtidensButik, newHeaderMenu} from "@atg-shared/client-features";
import {LazyFramtidensButik} from "@atg-payment-shared/framtidens-butik-feature";
import * as AppWebViews from "@mobileapp-shared/atgse-bridge/components/AppWebViews";
import {Paths} from "@atg-tillsammans-shared/navigation";

// micro frontend loaders
// (might replace this with federated modules in webpack@5 sometime?)
import type {EmotionCache} from "atg-emotion-cache";
import {store as frameStore} from "../store";
import DynamicRoutes from "./DynamicRoutes";
import {globalStyles} from "./App.global.styles";
import BaseFrame from "./BaseFrame";
import Providers from "./Providers";

const LazyPlayExperimentalTogglePage = createLazyComponentLoader(() =>
    import(
        /* webpackChunkName: "CurityHaapiTogglePage" */ "./PlayExperimentalTogglePage"
    ),
);

const LazyInternalRoutes = createLazyComponentLoader(() =>
    import(/* webpackChunkName: "InternalRoutes" */ "./internal/internalRoutes"),
);

const LazyHeaderMenuTogglePage = createLazyComponentLoader(() =>
    import(/* webpackChunkName: "LazyHeaderMenuTogglePage" */ "./HeaderMenuTogglePage"),
);

type MainAppProps = {
    emotionCache: EmotionCache;
};

/**
 * This is the main route config. Its only responsibility is to select the correct micro frontend to
 * load, using as short portion of the URL as possible (*). It is then up to each micro frontend to
 * further load the rest of the page.
 *
 * (*) By keeping this logic as simple as possible, it will be possible for the product micro
 * frontends to deploy route config changes for their product without touching "the frame" (this
 * component + more), thus avoiding the need to have synced releases as much as possible.
 *
 * NOTE: We put `BaseFrame` here instead of inside the microFE for a very specific reason – to avoid
 * the `VideoFrame` from unmounting and remounting when you switch product vertical, causing the
 * video to stop playing.
 */
function MainApp({emotionCache}: MainAppProps) {
    // landing screen temporarily disabled - https://atgse.atlassian.net/browse/CUST-1356
    // const {shouldRenderLandingScreen} = useDisplayLandingScreen({
    //     skipDisplay: checkIsBetUrl(),
    // });

    return (
        <Providers emotionCache={emotionCache}>
            <Global styles={globalStyles} />
            {/* 
            // landing screen temporarily disabled - https://atgse.atlassian.net/browse/CUST-1356
            {shouldRenderLandingScreen && <LandingScreen />} 
            */}
            <Routes>
                {/*
                 * Global team entry routes
                 * NOTE: Global is also responsible for CMS articles, but those are handled in
                 * `DynamicRoutes.js` instead.
                 */}
                <Route path="/" element={<BaseFrame />}>
                    <Route index element={<HorseApp />} />
                    {/* playExperimental toggle */}
                    <Route
                        path="eb6dc731aacd4644b22cd104426e5d06"
                        element={<LazyPlayExperimentalTogglePage />}
                    />
                    {/* Sport team entry routes */}
                    {[
                        "sport/*",
                        "konto/mina-spel/sport", // remove when verticals are using new urls
                        "konto/mina-spel/big9", // remove when verticals are using new urls
                        "konto/kuponger/big9", // remove when verticals are using new urls
                        "konto/mina-spel/sportbok",
                        "konto/mina-spel/sportpool",
                        "konto/kuponger/sportpool",
                    ].map((path) => (
                        <Route key={path} path={path} element={<SportsbookApp />} />
                    ))}
                    {["konto/mina-spel", "konto/bevakningar", "konto/kuponger"].map(
                        (path) => (
                            <Route key={path} path={path} element={<HorseApp />} />
                        ),
                    )}
                    {[
                        "kampanj/*",
                        "konto/*",
                        "preview/*",
                        "kundservice/*",
                        "jackpot/*",
                        "julkalender/*",
                        "aretshast/*",
                        "mitt-konto",
                    ].map((path) => (
                        <Route key={path} path={path} element={<GlobalApp />} />
                    ))}
                    {[
                        "aterstall",
                        "atgvinnare",
                        "galopp/*",
                        "harryboy/*",
                        "idag/*",
                        "kod/:betCode?",
                        "ratta/:betCode",
                        "live",
                        "loggain",
                        "nyheter/*",
                        "reduceratpdf",
                        "skapakonto",
                        "spel/*",
                        "spelskolan",
                        "stallsnack",
                        "tips",
                        "video/*",
                        "videoarkiv/*",
                        "vmiv75",
                        "vr",
                        "elli/admin",
                        "elli/:token",
                        "79545bfb_1702_4ea0_803c_46edf64a863f",
                        "SGlyaW5nIGRldmVsb3BlcnMsIGpxdWVyeSBrbm93bGVkZ2UgaXMgYSBwbHVz",
                        "84675f2baf7140037b8f5afe54eef841", // radar debugger toggle
                        "bfb1e87ed6f04ca4a2c00bb10d7b0", // toggle for head 2 head test in more info modal
                        "431387eb7262e1cfc79b125eb8a67c60", // test VXY proxy solution
                        "/9f81f3c07476a0d97f6793673dd8e475", // new reduction metadata endpoint toggle
                        "/9f81f3c07476a0d97f6793673dd8e790", // betting trends toggle
                        // all URLs starting with `/V75`, `/raket` etc.
                        ...values(GameTypes).map((gameType) => `/${gameType}/*`),
                    ].map((path) => (
                        <Route key={path} path={path} element={<HorseApp />} />
                    ))}
                    {/*
                     * Shop team entry routes
                     * NOTE: Shop is also responsible for the actual shop pages, but those are handled
                     * in `DynamicRoutes.js` instead.
                     */}
                    {["andelar", "andelsspel", "om-andelar", "butiker"].map((path) => (
                        <Route key={path} path={`${path}/*`} element={<ShopApp />} />
                    ))}
                    {Features.isEnabled(framtidensButik) &&
                        [
                            // framtidens-butik is the project name, discovery is the "service" name
                            "/discovery/:gameCategory/:storeId/:gameType/:amount",
                            "/discovery/:gameCategory/:storeName/:shareId",
                        ].map((path) => (
                            <Route
                                key={path}
                                path={path}
                                element={<LazyFramtidensButik />}
                            />
                        ))}
                    {["payment/*", "account-settings/*"].map((path) => (
                        <Route key={path} path={path} element={<PaymentApp />} />
                    ))}
                    <Route path="messages/*" element={<AmlApp />} />
                    {Features.isEnabled(newHeaderMenu) && (
                        <Route path="kyc/*" element={<AmlApp />} />
                    )}
                    <Route path="rg/*" element={<RgApp />} />
                    <Route
                        path={`${Paths.TILLSAMMANS_NAMESPACE}/*`}
                        element={<TillsammansApp />}
                    />
                    {/* Casino team entry routes */}
                    <Route path="casino/*" element={<CasinoApp />} />
                    {/* Streaming team entry routes */}
                    <Route path="play/*" element={<PlayApp />} />
                    <Route path="/horse-retail/*" element={<HorseRetailApp />} />
                    {/* app paths */}
                    <Route path="app">
                        <Route path="link/*" element={<AppWebViews.AppDynamicLink />} />
                        <Route path="login" element={<AppWebViews.AppLogin />} />

                        <Route path="v2">
                            <Route path="login" element={<AppWebViews.AppLogin />} />
                            <Route path="deposit" element={<AppWebViews.AppDeposit />} />
                            <Route
                                path="account/*"
                                element={<AppWebViews.AppAccount store={frameStore} />}
                            />
                            <Route
                                path="qr"
                                element={<AppWebViews.AppTrackCommissionLandingPage />}
                            />
                            <Route
                                path="news/:slug"
                                element={<AppWebViews.AppContentfulArticle />}
                            />
                            <Route
                                path="cookies"
                                element={<AppWebViews.AppCookiesConsent />}
                            />
                        </Route>
                    </Route>
                    {/* new header menu toggle */}
                    <Route
                        path="/f5e155b224b3613c1c4658a72c905627"
                        element={<LazyHeaderMenuTogglePage />}
                    />
                    {/* Other entry points (owned by the frame) */}
                    {root.clientConfig.env !== "prod" && (
                        <Route path="internal/*" element={<LazyInternalRoutes />} />
                    )}
                    {/*
                     * Dynamic index routes
                     * These are routes where we can't statically (just by looking at the URL) decide
                     * which micro frontend is responsible. For example `/foo` could be a CMS article,
                     * or a shop ("butik").
                     *
                     * Going forward the idea is to essentially remove this section, by prefixing all
                     * dynamic URLs in a way that we can immediately know which frontend to load. More info
                     * in [FE-53].
                     */}
                    <Route path="*" element={<DynamicRoutes />} />
                </Route>
            </Routes>
        </Providers>
    );
}

export default MainApp;
