import {call, put, select, delay} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import log, {serializeError} from "@atg-shared/log";
import {DepositResponseStatus, DepositStatuses} from "@atg-payment-shared/deposit-utils";
import {type SwishDepositOption, DepositMethods} from "@atg-payment-shared/deposit-types";
import * as DepositActions from "../../actions/actions";
import * as DepositSelectors from "../../selectors/selectors";
import * as DepositApi from "../../api/api";
import {
    statusMessage,
    POLL_TIMEOUT_TICK,
    type DepositResponseError,
    errorMessage,
    logNotSuccessfulDeposit,
} from "../helpers/sagaHelpers";
import type {DepositResponseStatusTypes} from "../../domainTypes";
import {depositApiCall} from "../../api/api";

export function* checkSwishDirectDepositStatus(orderId: string): SagaIterator {
    const pollTimeout = 180 * 1000; // 3 minutes
    const waitUntilFirstCheck = 10_000; // 10 sec in ms

    // We do a initial waiting for 10 seconds, therefor timeSpent and nextCheck starts at 10 seconds
    let timeSpent = waitUntilFirstCheck;
    let nextCheck = waitUntilFirstCheck;
    let status: DepositResponseStatusTypes = DepositResponseStatus.IN_PROGRESS;

    yield delay(waitUntilFirstCheck);

    while (status === DepositResponseStatus.IN_PROGRESS && timeSpent <= pollTimeout) {
        try {
            if (timeSpent === nextCheck) {
                const response = yield call(
                    DepositApi.checkDepositStatus,
                    orderId,
                    DepositMethods["swish-e-commerce"],
                );
                status = response.data.status;
                nextCheck = timeSpent + 3000;
            }
        } finally {
            timeSpent += POLL_TIMEOUT_TICK;
            yield delay(POLL_TIMEOUT_TICK);
        }
    }

    yield put(DepositActions.depositFinalize());
    yield call(statusMessage, status);
    yield put(DepositActions.resetDepositIspending());
    yield put(DepositActions.setSwishDirectStep(DepositStatuses.SWISH_DIRECT_RESET));
}

export function* cancelSwishDirect(): SagaIterator {
    try {
        const swishDirectOrderId: string | null = yield select(
            DepositSelectors.orderIdSwishDirect,
        );
        if (swishDirectOrderId) {
            yield call(
                DepositApi.cancelInitiatedDeposit,
                swishDirectOrderId,
                DepositMethods["swish-e-commerce"],
            );
        }
        yield put(DepositActions.clearIframeDepositState());
        yield put(DepositActions.resetDepositIspending());
    } catch (e: unknown) {
        const err = e as DepositResponseError;
        log.error("cancelSwishDirectError: ", {error: serializeError(err)});
    }
}

export function* handleSwishDirectFlow(): SagaIterator {
    const amount = yield select(DepositSelectors.selectedDepositAmount);
    const option: SwishDepositOption | null = yield select(
        DepositSelectors.swishDirectOption,
    );

    if (!option) {
        log.error("handleSwishDirectFlow was called when swishDirectOption is null");
        return;
    }

    const payload = {
        amount,
        option,
    };

    try {
        const response = yield call(depositApiCall, payload);

        if (!response) {
            log.error("No response from BE when trying to initiate a deposit");
            return;
        }

        const {orderId} = response.data;

        yield put(DepositActions.setSwishDirectId(orderId));
        yield call(checkSwishDirectDepositStatus, orderId);
    } catch (e: unknown) {
        const err = e as DepositResponseError;

        // We get a lot of uncaught redux-saga error in Splunk, with
        // "Cannot read properties of undefined (reading 'meta'"
        // to see if the error comes from here, check if the error is of instance DepositResponseError
        // and if not, what is the error
        if ("response" in err && "meta" in err.response && "code" in err.response.meta) {
            yield call(logNotSuccessfulDeposit, err);
        } else {
            log.error(
                "the error in handleSwishDirectFlow is NOT a instance of DepositResponseError",
                {error: serializeError(err)},
            );
        }

        yield call(errorMessage, err.response);
    }
}
