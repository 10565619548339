import {call, put, takeLatest, takeLeading, takeEvery} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import log, {serializeError} from "@atg-shared/log";
import {DepositMessages} from "@atg-payment-shared/deposit-utils";
import {type AtgResponse} from "@atg-shared/fetch-types";
import {LimitsActions} from "@atg-responsible-gambling-shared/limits-domain";
import {DepositActionConstants} from "@atg-payment-shared/deposit-types";
import * as DepositActions from "../actions/actions";
import * as DepositApi from "../api/api";
import type {DepositOptionsResponse} from "../domainTypes";
import {type DepositResponseError} from "./helpers/sagaHelpers";
import {cancelSwishDirect, handleSwishDirectFlow} from "./swish/swishSagas";
import {
    finalizeDepositIframe,
    handleIFrameFlow,
    cancelDepositInIframe,
} from "./iframe/iframeSagas";
import {deleteBankCardFlow} from "./card/cardSagas";

export function* depositOptionsFlow(): SagaIterator {
    yield put(LimitsActions.fetchDepositLimits());
    let response: AtgResponse<DepositOptionsResponse>;
    try {
        response = yield call(DepositApi.fetchOptionsIframe);
    } catch (error: unknown) {
        const err = error as DepositResponseError;
        if (!err.response) {
            log.error(`Unknown error from BE for depositOptionsFlow, err: ${err}`);
            throw error;
        }

        if (err.response.data.status === "DENIED") {
            yield put(DepositActions.depositBlocked());
            return;
        }

        log.error(
            `Fetching payment options failed with status ${err.response.meta.code}`,
            {
                error: serializeError(error),
            },
        );

        yield put(
            DepositActions.fetchOptionsFailure(
                DepositMessages().DEPOSIT_OPTIONS_FETCH_ERROR,
            ),
        );

        return;
    }
    yield put(DepositActions.fetchOptionsSuccess(response.data));
}

export default function* depositSaga(): SagaIterator {
    yield takeEvery(DepositActionConstants.DEPOSIT_MONEY_SWISH, handleSwishDirectFlow);
    yield takeEvery(DepositActionConstants.DEPOSIT_MONEY_IFRAME, handleIFrameFlow);
    // Using takeLeading here makes sure that finalizeDepositIframe saga is triggered only once (on first dispatch)
    // even if the DEPOSIT_FINALIZE_IFRAME action is dispatched multiple times from the component before the call
    // to the BE has finished (this is to avoid a race condition in BE when finalizeDepositIframe is triggered multiple times in parallel)
    yield takeLeading(
        DepositActionConstants.DEPOSIT_FINALIZE_IFRAME,
        finalizeDepositIframe,
    );

    yield takeLatest(DepositActionConstants.DELETE_BANK_CARD, deleteBankCardFlow);
    yield takeLatest(DepositActionConstants.FETCH_OPTIONS, depositOptionsFlow);
    yield takeEvery(
        DepositActionConstants.CANCEL_DEPOSIT_IN_IFRAME,
        cancelDepositInIframe,
    );
    yield takeEvery(DepositActionConstants.CANCEL_SWISH_DIRECT, cancelSwishDirect);
}
