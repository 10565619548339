export enum PlayerIds {
    WARMUP_PLAYER_ID = "warmupPlayer",
    LIVE_PLAYER_ID = "live",
}

export const parseVideoName = (gameId?: string, trackName?: string) => {
    const regex = /.*_(.*_)\d+(_.*)/;
    return gameId && trackName
        ? gameId.replace(regex, `$1${trackName}$2`)
        : "missing gameId or trackName";
};
