import * as React from "react";
import root from "window-or-global";
import {createLazyComponentLoader} from "@atg-shared/lazy";
import {useOneTrustLoaded} from "@atg-frame-shared/one-trust";

const LazyCookieConsentBanner = createLazyComponentLoader(() =>
    import(/* webpackChunkName: "CookieConsentModal" */ "./CookieConsentModal"),
);

const LazyCookieConsentPreferenceCenter = createLazyComponentLoader(() =>
    import(
        /* webpackChunkName: "CookieConsentPreferenceCenter" */ "./PreferenceCenter/CookieConsentPreferenceCenter"
    ),
);

export function CookieConsent() {
    const oneTrustLoaded = useOneTrustLoaded();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const window: any = root;
    const [hideCookieBanner, setHideCookieBanner] = React.useState(false);
    const [openPreferenceCenter, setOpenPreferenceCenter] = React.useState(false);

    const closeCookieBanner = () => {
        setHideCookieBanner(true);
        setOpenPreferenceCenter(false);
    };

    React.useEffect(() => {
        const handleOpenCookieModal = () => {
            setOpenPreferenceCenter(true);
        };
        window.addEventListener("openCookieModal", handleOpenCookieModal);
        return () => {
            window.removeEventListener("openCookieModal", handleOpenCookieModal);
        };
    }, [window]);

    React.useEffect(() => {
        if (oneTrustLoaded && window.OneTrust) {
            const checkAndSetBannerVisibility = () => {
                if (window.OneTrust?.IsAlertBoxClosedAndValid?.()) {
                    closeCookieBanner();
                }
            };

            checkAndSetBannerVisibility(); // Check initially

            window.OneTrust?.OnConsentChanged(checkAndSetBannerVisibility); // Register callback
        }
    }, [oneTrustLoaded, window.OneTrust]);

    if (oneTrustLoaded && openPreferenceCenter)
        return (
            <LazyCookieConsentPreferenceCenter closeCookieBanner={closeCookieBanner} />
        );

    if (!oneTrustLoaded || hideCookieBanner) {
        return null;
    }

    return <LazyCookieConsentBanner closeCookieBanner={closeCookieBanner} />;
}
