import atgRequest from "@atg-shared/fetch";
import type {AtgResponse} from "@atg-shared/fetch-types";
import {CMS_SERVICE_URL} from "@atg-shared/service-url";
import TTLCache from "@atg/utils/TTLCache";
import memoize from "lodash/memoize";
import type {CmsPage, CmsArticle} from "./cms";

export type CmsPageResponse = AtgResponse<CmsPage>;
export type CmsArticleResponse = AtgResponse<CmsArticle>;

// the Umbraco CMS content is not likely to change very frequently so 30 min cache time should be OK, page reload will clear the cache
const CACHE_TIME_30_MINUTES = 30 * 60 * 1000;
const ttlCache = new TTLCache(CACHE_TIME_30_MINUTES);

const memoizedAtgRequest = memoize(atgRequest);

memoizedAtgRequest.cache = ttlCache;

const fetchCMS = <T>(path: string): Promise<AtgResponse<T>> =>
    memoizedAtgRequest(CMS_SERVICE_URL + path);

export const fetchCmsPage = (path: string): Promise<CmsPageResponse> => fetchCMS(path);

export const fetchCmsArticle = (id: string): Promise<CmsArticleResponse> =>
    fetchCMS(`/article/${id}`);

export const fetchCmsArticleByLink = (link: string): Promise<CmsArticleResponse> =>
    fetchCMS(`/page${link}`);

export const fetchMisc = (): Promise<AtgResponse<any>> =>
    fetchCMS("/site/atg.se/current/misc");
