/**
 * Names of the micro frontends
 *
 * For details about the microFE architecture see the [README](./README.md).
 */
export enum MicroFrontend {
    /** special "shell application" microFE */
    FRAME = "frame",

    /** Global microFE */
    GLOBAL = "global",

    /** Horse microFE */
    HORSE = "horse",

    /** Shop microFE */
    SHOP = "shop",

    /** Casino microFE */
    CASINO = "casino",

    /** Sports microFE */
    SPORTSBOOK = "sportsbook",

    /** Play microFE */
    PLAY = "play",

    /** My ATG microFE */
    TILLSAMMANS = "tillsammans",

    /** Aml microFE */
    AML = "aml",

    /** Payment microFE */
    PAYMENT = "payment",

    /** Rg microFE */
    RG = "rg",

    /** Video player microFE */
    VIDEO_PLAYER = "video-player",

    /** Navigation microFE */
    NAVIGATION = "navigation",

    /** Horse Retail microFE */
    HORSE_RETAIL = "horse-retail",
}
