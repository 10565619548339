// We have this list because we don't want to add the auth headers to all
// requests, only to requests that require them, so we don't expose the auth
// information to unkown URLs.
const authorizationHeaderSupportedURLList = [
    "/campaign/vmv75/user-registration",
    "/user/atg-card",
    "/user/auth",
    "/user/balance",
    "/user/bets",
    "/user/betting-lock",
    "/user/budget/",
    "/user/competitions",
    "/user/controlquestionanswer",
    "/user/coupons",
    "/user/deposit",
    "/user/events",
    "/user/gdpr",
    "/user/payin",
    "/user/payment/options",
    "/user/payout",
    "/user/riksandelar",
    "/user/self-exclusion",
    "/user/smsverification/",
    "/user/subscriptions",
    "/user/transactions",
    "/user/withdrawal",
    "/user",

    // TEP Pts
    "/tokenized-proxy/pts/gamtests",
    "/tokenized-proxy/pts/results",
    "/tokenized-proxy/pts/subscriptions",
    "/tokenized-proxy/pts/texts",

    // Coupons
    "/tokenized-proxy/horse-coupons",
    "/tokenized-proxy/service-horse-bet-history/api/v1/grading",
    "/tokenized-proxy/service-horse-bet-history/api/v1/groupbets",

    // live receipts
    "/tokenized-proxy/service-horse-bet-history/api/v1/single-bets",
    "/tokenized-proxy/service-horse-bet-history/api/v1/single-bet-responses/today",

    // Horse betting
    "/tokenized-proxy/horse-betting",
    "/tokenized-proxy/harry-subscription/api/v1/subscription",

    // Horse bet history
    "/tokenized-proxy/horse-bet-history/api/v2/bet/transactions",
    "/tokenized-proxy/horse-bet-history/api/v2/bet/receipts",
    "/tokenized-proxy/horse-bet-history/api/v2/refund/bet",

    // Horse bet history only CBH transactions
    "/tokenized-proxy/horse-bet-history/api/v3/bet/transactions",

    // TEP Horse-bet-notification
    "/tokenized-proxy/horse-bet-notification/api/v2/notification/preferences",

    // Reduction
    "/tokenized-proxy/horse-reduction-calculator",
    "/tokenized-proxy/hrc",
    "/tokenized-proxy/horse-batch-betting",

    // TEP Favorites
    "/tokenized-proxy/horse-favorites/api/v2/favorite",
    "/tokenized-proxy/horse-favorites/api/v2/favorites/preferences",
    "/services/tokenized-proxy/horse-favorites/api/v2/favorite/upcoming-starts",

    // TEP Payments
    "/tokenized-proxy/payment/api/v1/deposit/creditcard",
    "/tokenized-proxy/payment/api/v2/deposit/creditcard",
    "/tokenized-proxy/payment/api/v1/deposit/swish",
    "/tokenized-proxy/payment/api/v1/deposit/options",
    "/tokenized-proxy/payment/api/v1/deposit/options/creditcard",
    "/tokenized-proxy/payment/api/v1/deposit/trustly",
    "/tokenized-proxy/payment/api/v2/deposit/trustly",
    "/tokenized-proxy/payment/api/v1/deposit/limits",
    "/tokenized-proxy/payment/api/v1/withdrawal/options/bankAccount",
    "/tokenized-proxy/payment/api/v1/customer/requestbaselimitincrease",

    // TEP Payments new
    "/tokenized-proxy/payment/api/v2/withdrawal",
    "/tokenized-proxy/payment/api/v2/withdrawal/options",

    // TEP customer transactions
    "/tokenized-proxy/customer-transactions/api/v1/result",
    "/tokenized-proxy/customer-transactions/api/v1/result-now",
    "/tokenized-proxy/customer-transactions/api/v1/my-gambling-result",

    // TEP customization
    "/tokenized-proxy/cc/api/v1/customization",

    // TEP shopshare & team & coupon team
    "/tokenized-proxy/shopshare/api/v1/shops",
    "/tokenized-proxy/shopshare/api/v1/protected",
    "/tokenized-proxy/shopshare/api/v1/shares",
    "/tokenized-proxy/shopshare/api/v1/subscriptions",
    "/tokenized-proxy/shopshare/api/v1/sport-coupon",
    "/tokenized-proxy/team/api/v1/teams",
    "/tokenized-proxy/team/api/graphql",
    "/tokenized-proxy/team/api/v1/subscriptions",
    "/tokenized-proxy/team/api/v1/personalization",
    "/tokenized-proxy/team/api/v1/member",
    "/tokenized-proxy/couponteam/api/v1/coupon-team",
    "/tokenized-proxy/shopshare/api/v1/chat",
    "/tokenized-proxy/team/api/v2/chat",
    "/tokenized-proxy/team/api/v1/logs",

    // TEP Member & Member-auth
    "/tokenized-proxy/member-auth/api/v1/member/login/latest",
    "/tokenized-proxy/member-auth/api/v1/member/controlquestionanswer",
    "/tokenized-proxy/member-auth/api/v1/member/password",
    "/tokenized-proxy/member-auth/api/v1/member/smsverification/initiate",
    "/tokenized-proxy/member-auth/api/v1/member/smsverification/verify",
    "/tokenized-proxy/member-auth/api/v1/member/smsverification/off",
    "/tokenized-proxy/member-auth/api/v1/member/settings",
    "/tokenized-proxy/member-auth/api/v1/member/contactinfo",
    "/tokenized-proxy/wallet/api/v3/wallet/balance",
    "/tokenized-proxy/member/api/v3/member",
    "/tokenized-proxy/member/api/v4/member",
    "/tokenized-proxy/member/api/v3/atg-card",

    // RCS
    "/tokenized-proxy/rcs/api/v1/optins",

    // TEP Member Auth Recovery
    "/tokenized-proxy/member-auth/api/v1/recovery/username/postal",
    "/tokenized-proxy/member-auth/api/v1/recovery/username/email",
    "/tokenized-proxy/member-auth/api/v1/recovery/username/sms",
    "/tokenized-proxy/member-auth/api/v1/recovery/password/postal",
    "/tokenized-proxy/member-auth/api/v1/recovery/password/sms",

    // TEP Account-History
    "/tokenized-proxy/account-history/api/v1/account/transactions",

    // TEP Personal push
    "/tokenized-proxy/subman/api/v1/subscriptions/personal",

    // TEP Casino
    "/tokenized/yggdrasil/api/v1/launch",
    "/tokenized/isoft/api/v1/launch",
    "/tokenized/evolution/api/v1/launch",
    "/tokenized/nolimitcity/api/v1/launch",
    "/tokenized/elk/api/v1/launch",
    "/tokenized/playngo/api/v1/launch",
    "/tokenized/thunderkick/api/v1/launch",
    "/tokenized/pragmaticplay/api/v1/launch",
    "/tokenized/kiron/api/v1/launch",
    "/tokenized/slotmill/api/v1/launch",
    "/tokenized/pariplay/api/v1/launch",
    "/tokenized-proxy/rgs/api/v1/rg/constraint/vr",
    "/tokenized/relax/api/v1/launch",
    "/tokenized/evolution/api/v2/launch",
    "/tokenized/yggdrasil/api/v2/launch",
    "/tokenized/playngo/api/v2/launch",
    "/tokenized/nolimitcity/api/v2/launch",
    "/tokenized/thunderkick/api/v2/launch",
    "/tokenized/isoft/api/v2/launch",
    "/tokenized/elk/api/v2/launch",
    "/tokenized/relax/api/v2/launch",
    "/tokenized/pragmaticplay/api/v2/launch",
    "/tokenized/slotmill/api/v2/launch",
    "/tokenized/pariplay/api/v2/launch",
    "/tokenized/everymatrix/api/v2/launch",
    "/tokenized/everymatrix/api/v1/jackpot",

    "/tokenized-proxy/rgs/api/v2/rg/constraint/vr",

    // TEP Sport - Sportsbook (Kambi)
    "/tokenized/kambi/api/v1/launch",

    // TEP Sport - Big9
    "/tokenized/sports-coupon/api/v1/coupons",
    "/tokenized/sports-bet/api/v1/bet",
    "/tokenized/sports-bet/api/v2/bet",
    "/tokenized/sports-bet/api/v2/bet/reduced",
    "/tokenized/sports-bet/api/v3/receipts",

    // TEP Sport - Big9 (Partner Reduced Betting)
    "/tokenized/reducedbet/api/v1/coupons",
    "/tokenized/partner-reduced-betting-cache/api/v1/coupons",

    // champions (e.g. VM i V75)
    "/tokenized-proxy/horse-champions/api/v1",

    // ElliAssistant - Horse
    "services/tokenized-proxy/elli-gateway/api/horse",

    // RGS
    // Duplicate for other verticals as needed
    "/tokenized-proxy/rgs/api/v1/rg/CASINO",
    "/tokenized-proxy/rgs/api/v1/rg/netlosslimit",
    "/tokenized-proxy/rgs/api/v1/rg/netlosslimit/CASINO",
    "/tokenized-proxy/rgs/api/v1/rg/playtimelimit",
    "/tokenized-proxy/rgs/api/v1/rg/playtimelimit/CASINO",
    "/tokenized-proxy/rgs/api/v1/rg/selfexclusion",
    "/tokenized-proxy/rgs/api/v1/rg/selfexclusion/casinopanic",
    "/tokenized-proxy/rgs/api/v1/rg/logintimelimit",

    // KYC
    "tokenized-proxy/kyc/api/v1/questionnaire/",
    "tokenized-proxy/kyc/api/v1/questionnaire/can-see-questionnaire",
    "tokenized-proxy/kyc/api/v1/questionnaire/modal-required",

    // Track commission
    "tokenized/agency-bet-tracking/api/v1/track-bet-placed",

    // Customer Inbox
    "/services/tokenized-proxy/customer-inbox/",

    // Member segmentation
    "/tokenized-proxy/member-segmentation/api/v1/segmentation",
];

export default authorizationHeaderSupportedURLList;
