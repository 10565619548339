import {combineReducers} from "redux";
import {clientVersionReducer} from "@atg-shared/client-version";
import auth from "@atg-shared/auth/domain/authReducer";
import accessToken from "@atg-shared/auth/domain/accessTokenReducer";
import welcomeNewCustomerReducer from "@atg-global-shared/welcome-new-customer-data-access/src";
import {VerticalsReducer} from "@atg-global-shared/verticals";
import {userGamblingSummary} from "@atg-responsible-gambling-shared/user-gambling-summary-domain";
import {userGamblingResult} from "@atg-responsible-gambling-shared/user-gambling-result-domain";
import {createRouterReducer} from "@atg-shared/router";
import {reducer as realityCheckReducer} from "@atg-responsible-gambling-shared/reality-check-domain";
import {
    alertReducer,
    unauthorizedLoginAttemptAlertsReducer,
} from "@atg-global-shared/alerts-data-access";
import mainNavbar from "@atg-navigation-shared/main-navbar-data-access/src/domain/mainNavbarReducer";
import user from "@atg-global-shared/user/userReducer";
import {accountSettingsReducer} from "@atg-payment-shared/account-settings-domain";
import {memberReducer} from "@atg-global-shared/member-data-access";
import {paymentReducer as payment} from "@atg-payment-shared/money-page-domain";
import cms from "@atg-global-shared/legacy-cms/domain/cmsReducer";
import loginHAAPI from "@atg-login/data-access/src/domains/loginReducer";
import {limitsReducer as limits} from "@atg-responsible-gambling-shared/limits-domain";
import {responsibleGamingHeaderReducer as responsibleGamingHeader} from "@atg-responsible-gambling-shared/header-domain";
import {kycQuestionnaire} from "@atg-aml-shared/kyc-domain";
import memberRegister from "atg-member-register/domain/memberRegisterReducer";
import login from "atg-member-login/domain/loginReducer";
import logout from "atg-member-logout/domain/logoutReducer";
import toasts from "atg-ui-toast/domain/toastReducer";
import {modals, modalData} from "atg-modals/modalReducer";
import {activeBreakpoint} from "atg-match-media/domain/matchMediaReducer";
import mainMenu from "atg-main-menu/domain/mainMenuReducer";
import memberRecovery from "atg-member-recovery/domain/memberRecoveryReducer";
import history from "atg-history";

const createRootReducer = (asyncReducers: any = {}) =>
    combineReducers<any, any>({
        // reducers that are lazily injected later will end up here
        ...asyncReducers.root,
        activeBreakpoint, // TODO: deprecated, should be removed after refactoring to `atg-breakpoints`
        // alert reducer keeps track of closed alerts, refactor to use local storage directly (doesn't need to be a reducer)
        alert: alertReducer,
        auth,
        accessToken,
        clientUpdateAvailable: clientVersionReducer,
        cms,
        limits,
        loginHAAPI,
        welcomeNewCustomer: welcomeNewCustomerReducer,
        login,
        logout,
        mainMenu,
        mainNavbar,
        member: memberReducer,
        memberRecovery,
        memberRegister,
        modals,
        modalData,
        payment,
        realityCheck: realityCheckReducer,
        userGamblingSummary,
        userGamblingResult,
        router: createRouterReducer(history), // inherited in product microFE
        responsibleGamingHeader,
        kycQuestionnaire,
        toasts,
        unAuthorizedLogin: unauthorizedLoginAttemptAlertsReducer, // frame
        user,
        verticals: VerticalsReducer,
        accountSettings: accountSettingsReducer,
    });

export default createRootReducer;
