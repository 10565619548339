import * as React from "react";
import Features, {abTestOverlay} from "@atg-shared/client-features";
import {createLazyComponentLoader} from "@atg-shared/lazy";

const ABTestOverlay = createLazyComponentLoader(() =>
    import(
        /* webpackChunkName: "ABTestOverlay" */
        "./internal/ABTestOverlay"
    ),
);

function LazyABTestOverlay() {
    if (!Features.isEnabled(abTestOverlay)) {
        return null;
    }

    return <ABTestOverlay />;
}

export default LazyABTestOverlay;
