import type {SagaIterator} from "redux-saga";
import {call, put, select} from "redux-saga/effects";
import log, {serializeError} from "@atg-shared/log";
import * as Storage from "@atg-shared/storage";
import {MuiAlertTypes} from "@atg-global-shared/alerts-types";
import * as UserActions from "@atg-global-shared/user/userActions";
import * as UserActionTypes from "@atg-global-shared/user/userActionTypes";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import {
    DepositMessages,
    GENERIC_TECHNICAL_ERROR_CODES,
    getDepositErrorCodeMessage,
    DepositStatuses,
    DepositResponseStatus,
    DepositStorageKeys,
} from "@atg-payment-shared/deposit-utils";
import {type PaymentMessage} from "@atg-payment-shared/payment-status-message-types";
import {type DepositOption, DepositMethods} from "@atg-payment-shared/deposit-types";
import {noTrans} from "@atg-shared/no-trans";
import * as DepositActions from "../../actions/actions";
import * as DepositSelectors from "../../selectors/selectors";
import type {DepositResponseStatusTypes} from "../../domainTypes";

/**
 * Saga Helpers
 */

export const POLL_TIMEOUT_TICK = 1000;
export const DEPOSIT_AMOUNT_TYPE = "depositAmountType";
export const DEPOSIT_PRE_SELECTED_BTN_NR = "depositPreSelectedBtnNr";

export type DepositError = {
    meta: {
        code: number;
        statusText?: string;
    };
    data: {status: string; code?: number; message?: string; creditCardRetries?: number};
};

export type DepositResponseError = {
    response: DepositError;
};

export function getStoredUserDepositPreference(
    userName: string,
): DepositOption | undefined {
    // Gets all preferences stored in local storage
    // This could be multiple if more than one user uses the unit
    const storedUserDepositPreferences = Storage.getObject(
        DepositStorageKeys.userDepositPreferences,
    );

    if (!storedUserDepositPreferences) return undefined;

    // Gets the specific users preference
    const preference = storedUserDepositPreferences[userName] as DepositOption;

    if (!preference) return undefined;

    // If the value was stored as "swish" previously we handle is as "swish-e-commerce"
    // @ts-ignore
    if (preference.id === "swish") preference.id = "swish-e-commerce";

    return preference;
}

export function* storeUserDepositPreferences(
    selectedOption: DepositOption,
): SagaIterator {
    const userName = yield select(UserSelectors.getUsername);

    yield call(Storage.setObject, DepositStorageKeys.userDepositPreferences, {
        [userName]: selectedOption,
    });
}

export function* deleteStoredUserDepositPreference(userName: string): SagaIterator {
    const storedUserDepositPreferences = Storage.getObject(
        DepositStorageKeys.userDepositPreferences,
    );
    if (storedUserDepositPreferences) {
        // The local storage can store multiple users preferences, so only delete the current user
        delete storedUserDepositPreferences[userName];
        // Need to remove the current local storge and then save it again with the updated state
        Storage.removeItem(DepositStorageKeys.userDepositPreferences);
        yield call(
            Storage.setObject,
            DepositStorageKeys.userDepositPreferences,
            storedUserDepositPreferences,
        );
    }
}

export function* statusMessage(status: DepositResponseStatusTypes): SagaIterator {
    switch (status) {
        case DepositResponseStatus.COMPLETED:
        case DepositResponseStatus.DELAYED: {
            let delayed = false;
            let message = DepositMessages().PAYIN_SUCCESS;

            if (status === DepositResponseStatus.DELAYED) {
                message = DepositMessages().PAYIN_DELAYED;
                delayed = true;
            }

            // mandatory to fetch a user or user's balance
            const action = yield call(UserActions.fetchBalance);
            if (action && action.type === UserActionTypes.RECEIVE_BALANCE_ERROR) {
                message = DepositMessages().PAYIN_DELAYED;
            }

            const amount: string | null = yield select(
                DepositSelectors.selectedDepositAmount,
            );

            yield put(DepositActions.depositSuccess(Number(amount), message, delayed));

            const depositOption: DepositOption = yield select(
                DepositSelectors.selectedOption,
            );

            // If the user selects a new card when deposit, the next time default method should be existing card instead of new card
            if (depositOption.id === DepositMethods.newCard)
                depositOption.id = DepositMethods.existingCard;

            yield call(storeUserDepositPreferences, depositOption);

            yield put(DepositActions.clearDepositAnalytics());

            return;
        }
        case DepositResponseStatus.BUDGET_EXCEEDED:
            yield put(
                DepositActions.depositFailure(
                    DepositMessages().PAYIN_RETURN_MONEY_BUDGET_EXCEEDED,
                ),
            );
            return;
        case DepositResponseStatus.ERROR:
            yield put(DepositActions.depositFailure(DepositMessages().TECHNICAL_ERROR));
            return;
        case DepositResponseStatus.CANCELLED:
            yield put(
                DepositActions.depositFailure(DepositMessages().TRANSACTION_CANCELLED),
            );
            return;
        case DepositResponseStatus.DECLINED:
            yield put(DepositActions.depositPending(DepositMessages().PAYIN_DECLINED));
            return;
        default:
            yield put(DepositActions.depositPending(DepositMessages().PAYIN_IN_PROGRESS));
    }
}

function* errorMessageErrorCodes(
    text: string,
    title?: string,
    showCustomerServicePhoneNumber?: boolean,
): SagaIterator {
    const msgObject: PaymentMessage = {
        id: "deposit-error-message",
        text,
        type: MuiAlertTypes.ERROR,
        title,
        showCustomerServicePhoneNumber: showCustomerServicePhoneNumber ?? false,
    };
    return yield put(DepositActions.depositFailure(msgObject));
}

function* errorMessages({meta: {code}, data: {status}}: DepositError): SagaIterator {
    switch (code) {
        case 0:
            return yield put(
                DepositActions.depositFailure(DepositMessages().CONNECTION_LOST),
            );
        case 403:
            return yield put(
                DepositActions.depositFailure(DepositMessages().LOGIN_REQUIRED),
            );
        case 406:
            return yield put(
                DepositActions.depositFailure(DepositMessages().PAYIN_DENIED),
            );
        case 409:
            switch (status) {
                /**
                 * The DENIED status is not provided by the payment service BE but may be passed along from
                 * Payex when something goes wrong inside the payex flow (which is out of our control).
                 */
                case DepositStatuses.PAYEX_DENIED:
                    return yield put(
                        DepositActions.depositFailure(
                            DepositMessages().TRANSACTION_CANCELLED,
                        ),
                    );
                default:
                    return yield put(
                        DepositActions.depositFailure(
                            DepositMessages().PAYIN_IN_PROGRESS,
                        ),
                    );
            }
        case 429:
            return yield put(
                DepositActions.depositFailure(
                    DepositMessages().PAYIN_DECLINED_TOO_MANY_REQUESTS,
                ),
            );

        default:
            log.error(`Inserting money to atg account failed with status ${code}`);
            return yield put(
                DepositActions.depositFailure(DepositMessages().TECHNICAL_ERROR),
            );
    }
}

export function errorMessage({meta, data}: DepositError): SagaIterator {
    const status = data && data.status ? data.status : "";
    const code = data && data.code ? data.code : "";
    const codeAsString = code.toString();
    const cardRetriesLeft = data?.creditCardRetries;

    if (!code) return errorMessages({meta, data: {status}});

    const errorCodeIsTechnicalError =
        GENERIC_TECHNICAL_ERROR_CODES.includes(codeAsString);

    if (errorCodeIsTechnicalError) {
        const {title, text, showCustomerServicePhoneNumber} =
            DepositMessages().GENERIC_TECHNICAL_ERROR_MESSAGE;
        return errorMessageErrorCodes(text, title, showCustomerServicePhoneNumber);
    }

    const message = getDepositErrorCodeMessage(code, cardRetriesLeft);

    if (message) {
        const {title, text, showCustomerServicePhoneNumber} = message;
        return errorMessageErrorCodes(text, title, showCustomerServicePhoneNumber);
    }
    return errorMessages({meta, data: {status}});
}

export type EvaluateSelectedPaymentReturnType = "pre-selected" | "changed" | "unknown";

export function* evaluateSelectedPayment(): SagaIterator<EvaluateSelectedPaymentReturnType> {
    const userName = yield select(UserSelectors.getUsername);
    const storedSelectedOption = yield call(getStoredUserDepositPreference, userName);

    if (!storedSelectedOption) return "unknown";

    const selectedOption = yield select(DepositSelectors.selectedOption);

    return storedSelectedOption.id === selectedOption?.id ? "pre-selected" : "changed";
}

export const logNotSuccessfulDeposit = (err: DepositResponseError) => {
    const message = noTrans(`BE responded with code: ${err.response.meta.code}`);
    switch (err.response.meta.code) {
        // When these type of errors occur there is something wrong with BE service
        case 400:
        case 405:
        case 500:
        case 503:
            log.error(message, {error: serializeError(err)});
            break;
        // Other errors is "good" errors, the service is up and running but there is a fault on user side
        default:
            log.warn(message, {error: serializeError(err)});
            break;
    }
};
