import {t} from "@lingui/macro";
import {MuiAlertTypes} from "@atg-global-shared/alerts-types";
import {type PaymentMessage} from "@atg-payment-shared/payment-status-message-types";

function getGenericErrorTitle() {
    return t`Tekniskt fel`;
}

type DepositMessagesId =
    | "auth-required"
    | "card-remove-success"
    | "card-remove-fail"
    | "connection-lost"
    | "existing-card"
    | "deposit-options-fetch-error"
    | "technical-error"
    | "invalid-card"
    | "login-required"
    | "need-to-set-budget"
    | "payin-amount-exceeds-budget"
    | "payin-declined"
    | "payin-declined-too-many-requests"
    | "payin-delayed"
    | "payin-denied"
    | "payin-continue-in-popup"
    | "payin-in-progress"
    | "payin-amount-not-valid"
    | "payin-popup"
    | "payin-return-money-budget-exceeded"
    | "payin-success"
    | "payin-swish-mobile-number-format"
    | "payin-swish-mobile-number-required"
    | "payin-window-blocked"
    | "generic-technical-error"
    | "transaction-cancelled"
    | "wrong-password";

function getDepositMessagesText(id: DepositMessagesId): string {
    switch (id) {
        case "auth-required":
            return t`Din session har gått ut. Var god ladda om sidan.`;
        case "card-remove-success":
            return t`Bank-/kreditkort är borttaget.`;
        case "card-remove-fail":
            return t`Borttagning av bank-/kreditkort misslyckades.`;
        case "connection-lost":
            return t`Anslutningen till servern bröts. För att kontrollera status på den senaste transaktionen se Historik > Mina transaktioner.`;
        case "existing-card":
            return t`Du har redan ett kort registrerat som kommer att tas bort när du byter kort.`;
        case "deposit-options-fetch-error":
            return t`Tyvärr kan du för närvarande inte sätta in pengar på grund av ett tekniskt fel. Försök igen senare eller kontakta vår kundservice.`;
        case "technical-error":
            return t`Tyvärr kunde vi inte genomföra din insättning. Försök igen senare eller kontakta vår kundservice på`;
        case "invalid-card":
            return t`Insättningen misslyckades. Vänligen kontrollera att uppgifterna för bank-/kreditkort stämmer.`;
        case "login-required":
            return t`Logga in på nytt för att genomföra din insättning.`;
        case "need-to-set-budget":
            return t`För att göra en insättning så behöver du ange en insättningsgräns.`;
        case "payin-amount-exceeds-budget":
            return t`Beloppet du angav överskrider din insättningsgräns. Vid frågor kontakta ATG Kundservice`;
        case "payin-declined":
            return t`Insättningen nekades. Prova gärna med någon annan insättningsmetod.`;
        case "payin-declined-too-many-requests":
            return t`Insättningen misslyckades. För många anrop.`;
        case "payin-delayed":
            return t`Insättningen kunde inte verifieras. Var god kontrollera ditt bankkonto eller försök igen.`;
        case "payin-denied":
            return t`Insättning medges ej.`;
        case "payin-continue-in-popup":
            return t`Fortsätt med insättningen i det nya fönstret.`;
        case "payin-in-progress":
            return t`Aj då, något oväntat verkar ha inträffat. Vänligen kontrollera din påbörjade insättning.`;
        case "payin-amount-not-valid":
            return t`Ogiltigt belopp, måste vara mellan 50 och 9 000 000 kr.`;
        case "payin-popup":
            return t`Obs! För att det nya fönstret skall visas måste din webbläsare tillåta "popupfönster" för ATG.se.`;
        case "payin-return-money-budget-exceeded":
            return t`Beloppet du angav överskrider din insättningsgräns. Pengar som dragits kommer att returneras. Vid frågor kontakta ATG Kundservice`;
        case "payin-success":
            return t`Insättningen genomförd`;
        case "payin-swish-mobile-number-format":
            return t`Felaktigt format på telefonnummer.`;
        case "payin-swish-mobile-number-required":
            return t`Du har inte angett något telefonnummer.`;
        case "payin-window-blocked":
            return t`Din webläsare blockerade pop-up fönstret. Var god stäng av funktionen och försök igen.`;
        case "transaction-cancelled":
            return t`Transaktionen avbruten.`;
        case "wrong-password":
            return t`Fel lösenord.`;
        case "generic-technical-error":
        default:
            return t`Just nu har vi tekniska problem. Försök igen senare.`;
    }
}

export const DepositMessages = () => ({
    AUTH_REQUIRED: {
        id: "auth-required",
        text: getDepositMessagesText("auth-required"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    CARD_REMOVE_SUCCESS: {
        id: "card-remove-success",
        text: getDepositMessagesText("card-remove-success"),
        type: MuiAlertTypes.SUCCESS,
    } as PaymentMessage,

    CARD_REMOVE_FAIL: {
        id: "card-remove-fail",
        text: getDepositMessagesText("card-remove-fail"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    CONNECTION_LOST: {
        id: "connection-lost",
        text: getDepositMessagesText("connection-lost"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    EXISTING_CARD_MESSAGE: {
        id: "existing-card",
        text: getDepositMessagesText("existing-card"),
        type: MuiAlertTypes.WARNING,
    } as PaymentMessage,

    DEPOSIT_OPTIONS_FETCH_ERROR: {
        id: "deposit-options-fetch-error",
        text: getDepositMessagesText("deposit-options-fetch-error"),
        title: getGenericErrorTitle(),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    GENERIC_TECHNICAL_ERROR_MESSAGE: {
        id: "technical-error",
        text: getDepositMessagesText("technical-error"),
        type: MuiAlertTypes.ERROR,
        title: getGenericErrorTitle(),
        showCustomerServicePhoneNumber: true,
    } as PaymentMessage,

    INVALID_CARD: {
        id: "invalid-card",
        text: getDepositMessagesText("invalid-card"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    LOGIN_REQUIRED: {
        id: "login-required",
        text: getDepositMessagesText("login-required"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    NEED_TO_SET_BUDGET: {
        id: "need-to-set-budget",
        text: getDepositMessagesText("need-to-set-budget"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    PAYIN_AMOUNT_EXCEEDS_BUDGET: {
        id: "payin-amount-exceeds-budget",
        text: getDepositMessagesText("payin-amount-exceeds-budget"),
        type: MuiAlertTypes.ERROR,
        showCustomerServicePhoneNumber: true,
    } as PaymentMessage,

    PAYIN_DECLINED: {
        id: "payin-declined",
        text: getDepositMessagesText("payin-declined"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    PAYIN_DECLINED_TOO_MANY_REQUESTS: {
        id: "payin-declined-too-many-requests",
        text: getDepositMessagesText("payin-declined-too-many-requests"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    PAYIN_DELAYED: {
        id: "payin-delayed",
        text: getDepositMessagesText("payin-delayed"),
        type: MuiAlertTypes.WARNING,
    } as PaymentMessage,

    PAYIN_DENIED: {
        id: "payin-denied",
        text: getDepositMessagesText("payin-denied"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    PAYIN_IN_POPUP: {
        id: "payin-continue-in-popup",
        text: getDepositMessagesText("payin-continue-in-popup"),
        type: MuiAlertTypes.INFO,
    } as PaymentMessage,

    PAYIN_IN_PROGRESS: {
        id: "payin-in-progress",
        text: getDepositMessagesText("payin-in-progress"),
        type: MuiAlertTypes.WARNING,
    } as PaymentMessage,

    PAYIN_INVALID_AMOUNT: {
        id: "payin-amount-not-valid",
        text: getDepositMessagesText("payin-amount-not-valid"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    PAYIN_POPUP_WARNING: {
        id: "payin-popup",
        text: getDepositMessagesText("payin-popup"),
        type: MuiAlertTypes.WARNING,
    } as PaymentMessage,

    PAYIN_RETURN_MONEY_BUDGET_EXCEEDED: {
        id: "payin-return-money-budget-exceeded",
        text: getDepositMessagesText("payin-return-money-budget-exceeded"),
        type: MuiAlertTypes.ERROR,
        showCustomerServicePhoneNumber: true,
    } as PaymentMessage,

    PAYIN_SUCCESS: {
        id: "payin-success",
        text: getDepositMessagesText("payin-success"),
        type: MuiAlertTypes.SUCCESS,
    } as PaymentMessage,

    PAYIN_SWISH_MOBILE_NUMBER_FORMAT: {
        id: "payin-swish-mobile-number-format",
        text: getDepositMessagesText("payin-swish-mobile-number-format"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    PAYIN_SWISH_MOBILE_NUMBER_REQUIRED: {
        id: "payin-swish-mobile-number-required",
        text: getDepositMessagesText("payin-swish-mobile-number-required"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    TECHNICAL_ERROR: {
        id: "generic-technical-error",
        text: getDepositMessagesText("generic-technical-error"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,

    TRANSACTION_CANCELLED: {
        id: "transaction-cancelled",
        text: getDepositMessagesText("transaction-cancelled"),
        type: MuiAlertTypes.WARNING,
    } as PaymentMessage,

    WRONG_PASSWORD: {
        id: "wrong-password",
        text: getDepositMessagesText("wrong-password"),
        type: MuiAlertTypes.ERROR,
    } as PaymentMessage,
});

export const GENERIC_TECHNICAL_ERROR_CODES: string[] = [
    "6001",
    "6003",
    "6004",
    "6007",
    "8008",
    "8009",
    "8011",
    "8012",
    "8014",
    "8018",
];

type DepositErrorCodeMessage = {
    title: string;
    text: string;
    showCustomerServicePhoneNumber: boolean;
    cardRetriesLeft?: number;
};

// Starts with 6 === Credit Card & Trustly deposit
// Starts with 7 === Generic
// Starts with 8 === Swish deposit
export const getDepositErrorCodeMessage = (
    errorCode: number,
    cardRetriesLeft?: number,
): DepositErrorCodeMessage | undefined => {
    switch (errorCode) {
        case 6002:
            return {
                title: t`Kortinformationen verkar vara fel`,
                text: t`Vi kunde inte hitta det angivna kortet. Kontrollera kortuppgifterna eller prova en annan insättningsmetod.`,
                showCustomerServicePhoneNumber: false,
            };
        case 6005:
            return {
                title: t`Tekniskt fel`,
                text: t`Tyvärr kunde vi inte genomföra din insättning på grund av ett tekniskt fel hos vår betalningspartner. Försök igen senare eller prova en annan insättningsmetod. För mer information kontakta vår kundservice på`,
                showCustomerServicePhoneNumber: true,
            };
        case 6006:
            return {
                title: t`Ogiltigt kort`,
                text: t`Tyvärr kunde vi inte genomföra din insättning eftersom ditt betalningskort inte kan användas på ATG. Prova igen med ett annat kort eller använd en annan insättningsmetod. För mer information om kortet kontakta din kortutgivare.`,
                showCustomerServicePhoneNumber: false,
            };
        case 6009:
            return {
                title: t`Försök igen`,
                text: t`Du har ${cardRetriesLeft} försök kvar att genomföra en insättning med detta kort, efter det kommer kortet spärras.`,
                showCustomerServicePhoneNumber: false,
            };
        case 6010:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Daglig gräns för försök med detta kort är nådd. Kontakta din kortutgivare för mer information.`,
                showCustomerServicePhoneNumber: false,
            };
        case 6011:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Månatlig gräns för försök med detta kort är nådd. Kontakta din kortutgivare för mer information.`,
                showCustomerServicePhoneNumber: false,
            };
        case 6012:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Ditt kort är spärrat. Kontakta din kortutgivare för mer information.`,
                showCustomerServicePhoneNumber: false,
            };
        case 6013:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Ditt kort är spärrat. Kontakta din kortutgivare för mer information.`,
                showCustomerServicePhoneNumber: false,
            };
        case 7002:
            return {
                title: t`Spärrat konto`,
                text: t`Ditt konto är spärrat för insättning och uttag. Vid frågor kontakta ATG Kundservice på`,
                showCustomerServicePhoneNumber: true,
            };
        case 7003:
            return {
                title: t`Felaktigt belopp`,
                text: t`Beloppet du angav överskrider din insättningsgräns. Vid frågor kontakta ATG Kundservice på`,
                showCustomerServicePhoneNumber: true,
            };
        case 7004:
            return {
                title: t`Felaktigt belopp`,
                text: t`Minsta belopp är 50 kr`,
                showCustomerServicePhoneNumber: false,
            };
        case 7011:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Ditt konto är tillfälligt spärrat för spel. Vänligen kontakta ATG Kundservice.`,
                showCustomerServicePhoneNumber: true,
            };
        case 7017:
            return {
                title: t`Vi behöver din adress`,
                text: t`Tyvärr har ditt ATG-konto spärrats för att sätta in pengar och spela, på grund av att dina adressuppgifter är otillräckliga. Men lugn, om det bara är dina adressuppgifter som gör att ditt konto är spärrat så behöver du bara informera Skatteverket om dessa, så öppnas ditt konto igen.`,
                showCustomerServicePhoneNumber: false,
            };
        case 8007:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Det angivna mobilnumret är ogiltigt för Swish. Kontrollera dina Swish-inställningar eller kontakta din bank.`,
                showCustomerServicePhoneNumber: false,
            };
        case 8010:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Beloppet överskrider beloppsgränsen på din bank. Vänligen kontakta din bank för mer information.`,
                showCustomerServicePhoneNumber: false,
            };
        case 8013:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Beloppet är större än din beloppsgräns för Swish. Kontrollera dina Swish-inställningar eller kontakta din bank.`,
                showCustomerServicePhoneNumber: false,
            };
        case 8015:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`En betalning har redan påbörjats. Vänligen kontrollera din påbörjade insättning.`,
                showCustomerServicePhoneNumber: false,
            };
        case 8016:
        case 8017:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Tyvärr kan vi inte hitta det angivna mobilnumret hos Swish. Vänligen kontrollera mobilnumret eller dina Swish-inställningar.`,
                showCustomerServicePhoneNumber: false,
            };
        case 8019:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Åldersgränsen på spel är 18 år. Mobilnumret tillhör en person som inte har fyllt 18 år.`,
                showCustomerServicePhoneNumber: false,
            };
        case 8020:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Mobilnumret verkar inte vara ditt egna Swish-anslutna telefonnummer. Kontrollera numret eller dina Swish-inställningar.`,
                showCustomerServicePhoneNumber: false,
            };
        case 8028:
            return {
                title: t`Transaktionen misslyckades`,
                text: t`Uttaget nekat av din bank. Kontakta banken för mer information om varför.`,
                showCustomerServicePhoneNumber: false,
            };
        default:
            return undefined;
    }
};

const DEPOSIT_SUCCESSFUL_ID = "payin-success";
export const isDepositSuccessful = (statusMessage: PaymentMessage) =>
    statusMessage.id === DEPOSIT_SUCCESSFUL_ID;
