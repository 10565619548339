/* eslint-disable import/no-import-module-exports */
// affects how code-split code (e.g. from `node_modules`) is loaded, so should come absolutely first
import "@atg-shared/micro-frontend/setPublicPath";
// polyfills – should be imported early, before any code that might use APIs that might need polyfilling)
import "@atg-shared/web-polyfills";
// exposed singletons - must be imported before any other microFE is loaded
import "@atg-frame/singletons";
// This needs to be imported before anything else, in case request is a prerender one
// speedy needs to be disabled before any css rules have been evaluated
// eslint-disable-next-line import/order
import {configureEmotionCache} from "atg-emotion-cache";
import * as React from "react";
import {createRoot} from "react-dom/client";
// We're temporarily using the legacy render method here to be able to compare the performance against the new createRoot api
// Ref: https://atgse.atlassian.net/browse/PAW-315
// eslint-disable-next-line react/no-deprecated
import {render} from "react-dom";
import root from "window-or-global";
import log, {serializeError, serverLogPlugin} from "@atg-shared/log";
import {Paths} from "@atg-tillsammans-shared/navigation";
import {trackGlobalEvent} from "@atg-global-shared/analytics";
import {configureStoreForAuthentication} from "@atg-shared/auth";
import Features, {lastVertical, react18ConcurrentMode} from "@atg-shared/client-features";
import {initialiseLanguage} from "@atg-shared/language";
import {getSetting} from "@atg-shared/api-mock-utils";
import history from "atg-history";
import App from "./components/App";
import {initAndSetMswHandlers} from "./msw/mswLazySetup";
import "./less/main.less";
import {store as frameStore} from "./store";
import {checkIsBetUrl} from "./utils";

const emotionCache = configureEmotionCache("frame");

// cleanup non-override features (overrides that match the default value) from localStorage
Features.removeNonOverrides();

root.log = log;

/**
 * Log errors that have propagated all the way to the root to Splunk
 */
const logRootErrors = () => {
    const isScriptError = /^Script error.?$/;

    root.addEventListener("error", (event: ErrorEvent) => {
        const {message, filename, lineno, colno, error} = event;

        if (isScriptError.test(message)) return;

        log.error(message, {
            error: serializeError(error),
            file: filename,
            lineNumber: lineno,
            column: colno,
        });
    });
};

initAndSetMswHandlers();

initialiseLanguage(async (locale) => {
    const {messages} = await import(
        /* webpackChunkName: "i18n" */ `../locales/${locale}/messages.po`
    );
    return messages;
});
const mockServiceApiActive = getSetting("mockServiceApi") === true;

if (mockServiceApiActive && root.clientConfig.env !== "prod") {
    /**
     * Lazily initialize API mock only if the "mockServiceApi" setting is true and we're not running in production.
     * This is important to prevent the API mocks and msw from becoming part of the production bundle
     * */
    import(/* webpackChunkName: "InitApiMock" */ "@atg-shared/api-mock-feature").then(
        ({initMockApi}) => initMockApi(),
    );
}

const elementRoot = root.document.getElementById("react-root");

if (Features.isEnabled(react18ConcurrentMode)) {
    const reactRoot = createRoot(elementRoot as HTMLElement);
    reactRoot.render(<App emotionCache={emotionCache} />);
} else {
    render(<App emotionCache={emotionCache} />, elementRoot);
}

const isLastVerticalEnabled = Features.isEnabled(lastVertical);
const lastVerticalFromStorage = localStorage.getItem("lastVertical");
const isBetUrl = checkIsBetUrl();

// Check if the referrer is from a search engine
const isFromSearchEngine = /google|bing|yahoo|duckduckgo|baidu|yandex|ask|aol/i.test(
    document.referrer,
);

if (
    window.location.pathname === "/" &&
    !isBetUrl &&
    isLastVerticalEnabled &&
    !isFromSearchEngine
) {
    trackGlobalEvent({
        event: "lastknownvertical",
        vertical: `${lastVerticalFromStorage}`,
    });
    if (isLastVerticalEnabled) {
        switch (lastVerticalFromStorage) {
            case "sport":
                history.replace("/sport");
                break;
            case "horse":
                history.replace("/");
                break;
            case "casino":
                history.replace("/casino");
                break;
            case "harryboy":
                history.replace("/harryboy");
                break;
            case "shops":
                history.replace("/andelar");
                break;
            case "tillsammans":
                history.replace(Paths.getTillsammansHomePath());
                break;
            default:
                break;
        }
    }
}

// @ts-expect-error
const hotModule = module.hot;
if (hotModule) {
    hotModule.accept();
}

import(/* webpackChunkName: "LegacyApp" */ "./components/LegacyApp").then((LegacyApp) => {
    LegacyApp.setUpTools();
    LegacyApp.addBodyClasses();
    LegacyApp.listenToHeartBeatPush();
    LegacyApp.addLinkHooks();
});

serverLogPlugin(log, "frame");
log.setLevel("warn");
logRootErrors();
configureStoreForAuthentication(frameStore);
