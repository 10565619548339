import {createTheme as createMuiTheme} from "@mui/material/styles";
import type {ThemeOptions, PaletteOptions} from "@mui/material/styles";
import type {TypographyOptions} from "@mui/material/styles/createTypography";
import {svSE} from "@mui/material/locale";
import defaultBreakpoints from "./breakpoints";
import createMixins from "./createMixins";
import createOverrides from "./createOverrides";
import createPalette from "./createPalette";
import createTypography from "./createTypography";
import createShadows from "./createShadows";
// import shape from "./shape"; keeping this for refernce or future use
// import spacing from "./spacing"; keeping this for refernce or future use
// import zIndex from "./zIndex"; keeping this for refernce or future use

/**
 * Custom theme options for customizing the ATG theme
 * Our theme options differ slightly from Muis since we generate options that
 * are then passed to the Mui createTheme function
 */
export interface ATGThemeOptions extends Omit<ThemeOptions, "typography"> {
    /**
     * This type differs slighly from the original Mui type since we only return options
     * to be used inside of Mui createTheme function
     */
    typography?:
        | Partial<TypographyOptions>
        | ((palette: PaletteOptions) => TypographyOptions);
}

/**
 * custom `createTheme` wrapper to help generate options for Muis `createTheme` function
 *
 * **Note**: if you only need to access the current theme in a component, you should use the `useTheme` hook instead. Only use this function if you need to create a new theme object.
 */
export default function createTheme(options: ATGThemeOptions = {}) {
    const {
        mixins: mixinsInput = {},
        palette: paletteInput = {},
        typography: typographyInput = {},
        breakpoints: breakpointsInput = defaultBreakpoints,
        spacing = 8,
        shape = {},
        zIndex = {},
        shadows = createShadows(),
        ...other
    } = options;

    const palette = createPalette(paletteInput);
    const typography = createTypography(
        palette,
        typographyInput as
            | TypographyOptions
            | ((palette: PaletteOptions) => TypographyOptions),
    );

    const theme = createMuiTheme(
        {
            breakpoints: breakpointsInput,
            components: createOverrides(),
            palette,
            typography,
            shape, // default shape is 4px borde-radius – add a shape file if we want to edit this
            spacing, // default spacing is 8px – add a spacing file if we want to edit this
            zIndex, // needs discussion with designers & devs. default values suffice
            shadows,
        },
        svSE,
        other,
    );

    /**
     * Our custom mixins solution requres the theme to already be built. Thus we
     * simply overwrite the theme.mixins like below
     */
    theme.mixins = createMixins(theme.breakpoints, theme.spacing, mixinsInput);

    return theme;
}
