import {addAuthHeaders, fetchFileUpload} from "@atg-shared/fetch";
import {fetchAuthorized} from "@atg-shared/auth";
import {MEMBER_SERVICE_V4_URL} from "@atg-shared/service-url";
import type {ImageFilePayload} from "@atg-tillsammans-shared/image-types";

export const mapFormData = (payload: ImageFilePayload): FormData => {
    const formData = new FormData();
    formData.append("file", payload.file);
    return formData;
};

export const saveAvatar = (payload: ImageFilePayload) =>
    fetchAuthorized<{imageUrl: string}>(
        `${MEMBER_SERVICE_V4_URL}/image`,
        {
            method: "POST",
            headers: addAuthHeaders({}),
            body: mapFormData(payload),
        },
        undefined,
        fetchFileUpload,
    );

export const removeAvatar = () =>
    fetchAuthorized<unknown>(`${MEMBER_SERVICE_V4_URL}/image`, {
        method: "DELETE",
    });
