import {now} from "@atg-shared/datetime";
import {fetchAuthorized} from "@atg-shared/auth";
import log from "@atg-shared/log";
import {
    DepositURLs,
    type DepositResponseStatusType,
} from "@atg-payment-shared/deposit-utils";
import type {
    DepositMethod,
    SwishDepositOption,
    TrustlyDepositOption,
    CreditCardDepositOption,
    DepositOption,
} from "@atg-payment-shared/deposit-types";
import {DepositMethods} from "@atg-payment-shared/deposit-types";
import type {DepositOptionsResponse} from "../domainTypes";

export const getEndpoint = (option: DepositMethod): string => {
    switch (option) {
        case DepositMethods.existingCard:
        case DepositMethods.newCard:
            return DepositURLs.CREDIT_CARD_URL;
        case DepositMethods["swish-e-commerce"]:
            return DepositURLs.SWISH_DIRECT_URL;
        case DepositMethods.trustly:
            return DepositURLs.TRUSTLY_URL;
        default:
            log.error(`depositApiCall called with unknown option: ${option}`);
            return "";
    }
};

export type DepositMoneyProps = {
    amount: number;
    option: DepositOption;
    noCvc?: boolean;
    ssnValidation?: boolean;
    openInNewWindow?: boolean;
    mobilePhone?: string;
    phoneNumber?: string;
};

type DepositApiCallProps = {
    amount: number;
    option: SwishDepositOption | CreditCardDepositOption | TrustlyDepositOption;
    storeCard?: boolean;
};

export type DepositApiResponse = {
    orderId: number;
    amount: number;
    status: DepositResponseStatusType;
    redirectUrl: string;
    hostedViewUrl: string;
    paymentId: string;
};

export const depositApiCall = ({amount, option, storeCard}: DepositApiCallProps) =>
    fetchAuthorized<DepositApiResponse>(getEndpoint(option.id), {
        method: "POST",
        headers: {
            token: now().toString(),
        },
        body: JSON.stringify({
            amount,
            option,
            noCvc: true,
            storeCard,
            completeUrl: "https://atg.se",
            cancelUrl: "https://atg.se",
            ssnValidation: false,
        }),
    });

export type CheckStatusResponse = {
    orderId: number;
    amount: number;
    status: DepositResponseStatusType;
    redirectUrl: string;
    hostedViewUrl: string;
    paymentId: string;
};

export const checkDepositStatus = (orderId: string, depositMethod: DepositMethod) => {
    const endpoint = getEndpoint(depositMethod);
    return fetchAuthorized<CheckStatusResponse>(`${endpoint}/${orderId}`, {
        method: "GET",
    });
};

export const cancelInitiatedDeposit = (orderId: string, depositMethod: DepositMethod) => {
    const endpoint = getEndpoint(depositMethod);
    return fetchAuthorized(`${endpoint}/${orderId}`, {
        method: "DELETE",
    });
};

export const fetchOptionsIframe = () =>
    fetchAuthorized<DepositOptionsResponse>(DepositURLs.OPTIONS_URL);

export const deleteStoredBankCard = () =>
    fetchAuthorized(`${DepositURLs.OPTIONS_URL}/creditcard`, {
        method: "DELETE",
    });
