import {call, take, takeLatest, select} from "redux-saga/effects";
import type {AnyAction} from "redux";
import type {SagaIterator} from "redux-saga";
import {trackEvent} from "@atg-shared/analytics";
import {
    DepositActionConstants,
    type DepositFlow,
    type DepositOption,
} from "@atg-payment-shared/deposit-types";
import log from "@atg-shared/log";
import {noTrans} from "@atg-shared/no-trans";
import * as ModalActions from "atg-modals/modalActionTypes";
import type {
    DepositMoneyFailureAction,
    DepositMoneySuccessAction,
} from "../actions/actionTypes";
import * as DepositSelectors from "../selectors/selectors";
import {
    evaluateSelectedPayment,
    type EvaluateSelectedPaymentReturnType,
} from "../saga/helpers/sagaHelpers";
import * as DepositAnalytics from "./analytics";

const {
    DEPOSIT_SUCCESS,
    DEPOSIT_FAILURE,
    START_ABORT_OBSERVER,
    DEPOSIT_MONEY_SWISH,
    DEPOSIT_MONEY_IFRAME,
} = DepositActionConstants;

export type CommonReturnType = {
    flow: DepositFlow;
    amount: string;
    paymentMethod: string;
    preSelectedBtnNr: number | null;
};

type funcNames = "money" | "abortObserver" | "success" | "fail";

function* getCommonState(funcName: funcNames): SagaIterator<CommonReturnType> {
    const flow: DepositFlow = yield select(DepositSelectors.depositFlow);
    const amount: string = yield select(DepositSelectors.selectedDepositAmount) || "";
    const selectedOption: DepositOption | null = yield select(
        DepositSelectors.selectedOption,
    );
    const paymentMethod = selectedOption?.id ?? "unknown";
    const preSelectedBtnNr: number | null = yield select(
        DepositSelectors.preSelectedBtnNr,
    );

    // This will not break the payment flow, but the analytics will not be 100% complete
    // thats why log.warn instead of log.error
    if (!flow || !amount || !paymentMethod) {
        log.warn(
            `flow, amount and/or paymentMethod was null/undefined in depositAnalytics-${funcName}`,
            {
                additionalData: {flow, amount, paymentMethod},
            },
        );
    }

    return {flow, amount, paymentMethod, preSelectedBtnNr};
}

function* depositAnalyticsMoney(): SagaIterator {
    const {flow, amount, paymentMethod}: CommonReturnType = yield call(
        getCommonState,
        "money",
    );

    const depositStartEvent = yield call(
        DepositAnalytics.depositStart,
        paymentMethod,
        flow,
        amount,
    );
    yield call(trackEvent, depositStartEvent);
}

function* depositAnalyticsAbortObserver(): SagaIterator {
    const action: AnyAction = yield take([
        ModalActions.MODAL_CLOSE,
        DEPOSIT_FAILURE,
        DEPOSIT_SUCCESS,
    ]);

    if (action.type === (DEPOSIT_FAILURE || DEPOSIT_SUCCESS)) return;

    if (action.type === ModalActions.MODAL_CLOSE && action.payload === "depositModal") {
        const {flow, amount, paymentMethod}: CommonReturnType = yield call(
            getCommonState,
            "abortObserver",
        );
        const depositAbortEvent = yield call(
            DepositAnalytics.depositAbort,
            flow,
            amount,
            paymentMethod,
        );
        yield call(trackEvent, depositAbortEvent);
    }
}

function* depositAnalyticsSuccess(action: DepositMoneySuccessAction): SagaIterator {
    const {delayed: isDelayed} = action.payload;

    const {flow, amount, paymentMethod, preSelectedBtnNr}: CommonReturnType = yield call(
        getCommonState,
        "success",
    );
    const selectedPayment: EvaluateSelectedPaymentReturnType = yield call(
        evaluateSelectedPayment,
    );

    const depositSuccessEvent = yield call(
        DepositAnalytics.depositSuccess,
        amount,
        isDelayed,
        paymentMethod,
        preSelectedBtnNr,
        selectedPayment,
        flow,
    );
    yield call(trackEvent, depositSuccessEvent);
}

export function* depositAnalyticsFail(action: DepositMoneyFailureAction): SagaIterator {
    const {
        message: {title: failReason},
    } = action.payload;

    const {flow, amount, paymentMethod}: CommonReturnType = yield call(
        getCommonState,
        "fail",
    );

    const depositFailedEvent = yield call(
        DepositAnalytics.depositFail,
        paymentMethod,
        failReason ?? noTrans("Missing fail reason from BE"),
        flow,
        amount,
    );

    yield call(trackEvent, depositFailedEvent);
}

export default function* depositAnalyticsSaga(): SagaIterator {
    yield takeLatest([DEPOSIT_MONEY_SWISH, DEPOSIT_MONEY_IFRAME], depositAnalyticsMoney);

    yield takeLatest(START_ABORT_OBSERVER, depositAnalyticsAbortObserver);
    yield takeLatest(DEPOSIT_SUCCESS, depositAnalyticsSuccess);
    yield takeLatest(DEPOSIT_FAILURE, depositAnalyticsFail);
}

export const exportedForTesting = {
    depositAnalyticsMoney,
    depositAnalyticsAbortObserver,
    depositAnalyticsSuccess,
    depositAnalyticsFail,
    getCommonState,
};
