import {put, select, take, takeLatest, call} from "redux-saga/effects";
import type {SagaIterator} from "redux-saga";
import * as Analytics from "@atg-shared/analytics";
import {MemberActions} from "@atg-global-shared/member-data-access";
import * as UserSelectors from "@atg-global-shared/user/userSelectors";
import * as UserAPI from "@atg-global-shared/user/userApi";
import {waitForKycQuestionnaireModalClosed} from "@atg-aml-shared/kyc-domain/src/saga/kycQuestionnaireSaga";
import * as ModalSelectors from "atg-modals/modalSelectors";
import type {State as ModalsState} from "atg-modals/domain";
import * as ModalActions from "atg-modals/modalActions";

export const isShowTempPasswordChangeModalOpen = (state: ModalsState) =>
    state.modals.showChangeTempPasswordModal;
export const isLoginModalOpen = (state: ModalsState) => state.modals.showLoginModal;
export const isTwoFactorLoginConfirmationModalOpen = (state: ModalsState) =>
    state.modals.showTwoFactorLoginConfirmationModal;

export function* postLoginFlow(): SagaIterator {
    const loginModalIsOpen = yield select(isLoginModalOpen);
    if (loginModalIsOpen) yield put(ModalActions.closeLoginModal());

    const twoFactorLoginModalIsOpen = yield select(isTwoFactorLoginConfirmationModalOpen);
    if (twoFactorLoginModalIsOpen)
        yield put(ModalActions.closeTwoFactorLoginConfirmationModal());

    const forcePasswordChangeModalIsOpen = yield select(
        isShowTempPasswordChangeModalOpen,
    );
    if (forcePasswordChangeModalIsOpen)
        yield put(ModalActions.closeChangeTempPasswordModal());

    yield take(ModalActions.closeUserGamblingSummaryModal().type);

    // After user gambling summary modal, the kyc modal will pop up, if the user have question to answer
    const kycQuestionnaireModalIsOpen = yield select(
        ModalSelectors.shouldShowKycQuestionnaireModal,
    );
    if (kycQuestionnaireModalIsOpen) {
        yield call(waitForKycQuestionnaireModalClosed);
    }

    const missingContactInfo = yield select(UserSelectors.isMissingContactInfo);

    if (missingContactInfo) {
        // wait for the user gambling summary modal to be closed before opening modal

        yield put(ModalActions.showUpdateContactInformationModal());

        yield call(Analytics.deprecated_logEvent, {
            event: "show_contact_info_update_modal",
        });

        // wait for the contact info modal to be closed before continuing
        yield take(ModalActions.closeUpdateContactInformationModal().type);
        yield call(Analytics.deprecated_logEvent, {
            event: "close_contact_info_update_modal",
        });
    }

    const contactInformationResponse = yield call(
        UserAPI.fetchUserContactOptionsCustomer,
    );
    const contactInformation = contactInformationResponse.data;
    const isProtectedIdentity = yield select(UserSelectors.isProtectedIdentity);

    // protected identity user is forbidden to change their contact info
    if (!isProtectedIdentity && contactInformation.showModal) {
        yield put(ModalActions.showVerifyContactInformationModal());
        yield take(ModalActions.closeVerifyContactInformationModal().type);
    }
}

export default function* postLoginSaga() {
    yield takeLatest(MemberActions.FINISH_MEMBER_FLOW, postLoginFlow);
}
