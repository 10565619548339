import {min} from "lodash";
import {createSelector} from "reselect";
import {fetchSelectors, createInitialState} from "@atg-shared/fetch-redux";
import {
    RgsLimitTypeEnum,
    VerticalEnum,
} from "@atg-responsible-gambling-shared/limits-types";
import type {GlobalLimitsState} from "./limits.types";

export const getLoginTimeLimitsState = (state: GlobalLimitsState) =>
    state.limits.rgsLimits.loginTimeLimits;

export const getRemainingLoginTime = createSelector(
    getLoginTimeLimitsState,
    (loginTimeLimits) => {
        const {day, week, month} = loginTimeLimits;

        const remainings = [
            day ? day.remaining : undefined,
            week ? week.remaining : undefined,
            month ? month.remaining : undefined,
        ];

        return min(remainings) || 0;
    },
);

export const getLoginTimeLimitsLoadingState = (state: GlobalLimitsState) => {
    if (state.limits.rgsLimits.loginTimeLimits)
        return fetchSelectors.getLoadingState(state.limits.rgsLimits.loginTimeLimits);
    return undefined;
};

export const hasSetLoginTimeLimit = (state: GlobalLimitsState): boolean => {
    const loginTimeLimits = getLoginTimeLimitsState(state);

    const isLoginTimeLimitSet =
        loginTimeLimits?.day !== undefined ||
        loginTimeLimits?.week !== undefined ||
        loginTimeLimits?.month !== undefined;
    return isLoginTimeLimitSet;
};

export const getRgsLimits = (state: GlobalLimitsState) => state.limits.rgsLimits;

export const createRgsLimitsSelectors = (
    vertical: VerticalEnum,
    limitType: Exclude<RgsLimitTypeEnum, RgsLimitTypeEnum.LOGIN>,
) => {
    const baseSelector = createSelector(
        getRgsLimits,
        (state) =>
            state?.[limitType]?.[vertical] ||
            createInitialState({
                day: undefined,
                week: undefined,
                month: undefined,
                set: undefined,
            }),
    );

    const loadingStatus = fetchSelectors.createSelectors(baseSelector);

    const getLimitLoadingStatus = createSelector(
        loadingStatus.isLoading,
        loadingStatus.isLoaded,
        loadingStatus.hasError,
        (isLoading, isLoaded, hasError) => ({isLoading, isLoaded, hasError}),
    );

    const getDay = createSelector(baseSelector, (state) => state.day);
    const getWeek = createSelector(baseSelector, (state) => state.week);
    const getMonth = createSelector(baseSelector, (state) => state.month);
    const getIsSetting = createSelector(baseSelector, (state) => Boolean(state.set));

    return {
        getLoadingStatus: getLimitLoadingStatus,
        getDay,
        getWeek,
        getMonth,
        getIsSetting,
    };
};

export const {
    isLoading: isLoginTimeLimitLoading,
    isLoaded: isLoginTimeLimitLoaded,
    hasError: hasLoginTimeLimitError,
} = fetchSelectors.createSelectors(getLoginTimeLimitsState);

export const getLoginTimeLimitLoadingStatus = (state: GlobalLimitsState) =>
    fetchSelectors.getLoadingStatus(getLoginTimeLimitsState(state));

export const getDepositState = (state: GlobalLimitsState) =>
    state.limits && state.limits.deposit;

export const getDepositLimits = createSelector(
    getDepositState,
    (state) => state.depositLimits,
);

export const areAllDepositLimitsSet = createSelector(
    getDepositLimits,
    (limits) =>
        (limits &&
            limits.dailyLimit !== undefined &&
            limits.weeklyLimit !== undefined &&
            limits.monthlyLimit !== undefined) ??
        false,
);

export const getDepositLimitLoadingStatus = createSelector(
    getDepositState,
    (depositState) => fetchSelectors.getLoadingStatus(depositState),
);

export const shouldForceDepositBudgetUpdate = createSelector(
    areAllDepositLimitsSet,
    getDepositLimitLoadingStatus,
    (limitsSet, loadingStatus) => {
        const {status} = loadingStatus;
        if (status !== "ok") return false;

        return !limitsSet;
    },
);

export const getDepositLimitsLoadingState = createSelector(
    getDepositState,
    // eslint-disable-next-line no-underscore-dangle
    (depositState) => depositState.__loadingState,
);

export const getRemainingDepositBudget = createSelector(getDepositLimits, (limits) => {
    if (!limits || Object.keys(limits).length === 0) return null;
    const {dailyLimit, weeklyLimit, monthlyLimit} = limits;
    const remaining = [
        dailyLimit ? dailyLimit.remaining : undefined,
        weeklyLimit ? weeklyLimit.remaining : undefined,
        monthlyLimit ? monthlyLimit.remaining : undefined,
    ];

    return min(remaining);
});

export const onLowRemainingBudget = createSelector(
    getRemainingDepositBudget,
    (remainingDepositBudget) => {
        if (!remainingDepositBudget) return false;

        return remainingDepositBudget >= 5000 && remainingDepositBudget <= 14900;
    },
);

export const getDepositMaxLimit = createSelector(
    getDepositState,
    (depositState) => depositState.maxLimits?.[0],
);

export const horseNetLossLimitSelector = createRgsLimitsSelectors(
    VerticalEnum.HORSE_BETTING,
    RgsLimitTypeEnum.LOSS,
);

export const casinoNetLossLimitSelector = createRgsLimitsSelectors(
    VerticalEnum.CASINO,
    RgsLimitTypeEnum.LOSS,
);

export const sportNetLossLimitSelector = createRgsLimitsSelectors(
    VerticalEnum.SPORTSBOOK,
    RgsLimitTypeEnum.LOSS,
);

export const horseTimeLimitSelector = createRgsLimitsSelectors(
    VerticalEnum.HORSE_BETTING,
    RgsLimitTypeEnum.TIME,
);

export const casinoTimeLimitSelector = createRgsLimitsSelectors(
    VerticalEnum.CASINO,
    RgsLimitTypeEnum.TIME,
);

export const sportTimeLimitSelector = createRgsLimitsSelectors(
    VerticalEnum.SPORTSBOOK,
    RgsLimitTypeEnum.TIME,
);

export const horseLossLimitSelector = createRgsLimitsSelectors(
    VerticalEnum.HORSE_BETTING,
    RgsLimitTypeEnum.LOSS,
);

export const casinoLossLimitSelector = createRgsLimitsSelectors(
    VerticalEnum.CASINO,
    RgsLimitTypeEnum.LOSS,
);

export const sportLossLimitSelector = createRgsLimitsSelectors(
    VerticalEnum.SPORTSBOOK,
    RgsLimitTypeEnum.LOSS,
);

export const isNetLossLimitReached =
    (cost: number, vertical: VerticalEnum = VerticalEnum.HORSE_BETTING) =>
    (state: GlobalLimitsState): boolean => {
        let netLossLimitSelector;

        switch (vertical) {
            case VerticalEnum.HORSE_BETTING:
                netLossLimitSelector = horseNetLossLimitSelector;
                break;
            case VerticalEnum.SPORTSBOOK:
                netLossLimitSelector = sportNetLossLimitSelector;
                break;
            default:
                throw new Error(`Vertical not supported: ${vertical}`);
        }

        return (
            Boolean(netLossLimitSelector.getWeek(state)?.remaining) &&
            cost > (netLossLimitSelector.getWeek(state)?.remaining || 0)
        );
    };
