import * as React from "react";
import Features, {sessionTimer} from "@atg-shared/client-features";
import {createLazyComponentLoader} from "@atg-shared/lazy";

const SessionTimer = createLazyComponentLoader(
    () =>
        import(
            /* webpackChunkName: "SessionTimer" */
            "../internal/SessionTimer"
        ),
    {placeholder: null},
);

function LazySessionTimer() {
    if (!Features.isEnabled(sessionTimer)) {
        return null;
    }

    return <SessionTimer />;
}

export default LazySessionTimer;
