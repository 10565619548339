import {createLazyComponentLoader} from "@atg-shared/lazy";

const LazyModalFrame = createLazyComponentLoader(
    () =>
        import(
            /* webpackChunkName: "ModalFrame" */
            "./ModalFrame"
        ),
    {
        placeholder: null,
    },
);

export default LazyModalFrame;
