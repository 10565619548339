import * as React from "react";
import * as Redux from "react-redux";
import {ThemeProvider as EmotionThemeProvider} from "@emotion/react";
import {PersistGate} from "redux-persist/integration/react";
import {CastContextProvider} from "@atg-play-shared/global-player/domain/contexts/CastContext";
import {ErrorBoundary, ErrorFallback} from "@atg-shared/error-boundary";
import {MiniPlayerContextProvider} from "@atg/utils/player-util/contexts/MiniPlayerContext";
import {createTheme, ThemeProvider} from "@atg-ui/toolkit/theme";
import {HistoryRouter, RouterActions} from "@atg-shared/router";
import {EmotionCacheProvider, type EmotionCache} from "atg-emotion-cache";
import history from "atg-history";
import {broadcastAction} from "atg-store-addons";
import {store as frameStore, persistor} from "../store";
import {I18nProvider} from "@lingui/react";
import {i18n} from "@lingui/core";

const emitLocationChangeAction = (
    payload: Parameters<typeof RouterActions.locationChangedAction>[0],
) => frameStore.dispatch(broadcastAction(RouterActions.locationChangedAction(payload)));

type ProvidersProps = {
    children: React.ReactNode;
    emotionCache: EmotionCache;
};

// Hack for not blocking release, makes sure object assign polyfill is included
// TODO: please find a better way of including it. Probably "builtIns: entry"
// In babel config will work, instead of "usage"
// eslint-disable-next-line prefer-object-spread
Object.assign({}, {});
// Hack for making includes work with polyfill,
// See above should be fixed,
[""].includes("");

const theme = createTheme();

function Providers({children, emotionCache}: ProvidersProps) {
    return (
        <React.StrictMode>
            <ErrorBoundary name="FrameApp" placeholder={<ErrorFallback />}>
                <I18nProvider i18n={i18n}>
                    <EmotionCacheProvider value={emotionCache}>
                        <EmotionThemeProvider theme={theme}>
                            <ThemeProvider theme={theme}>
                                <Redux.Provider store={frameStore}>
                                    <HistoryRouter
                                        history={history}
                                        onLocationChange={emitLocationChangeAction}
                                    >
                                        <PersistGate loading={null} persistor={persistor}>
                                            <CastContextProvider>
                                                <MiniPlayerContextProvider>
                                                    {children}
                                                </MiniPlayerContextProvider>
                                            </CastContextProvider>
                                        </PersistGate>
                                    </HistoryRouter>
                                </Redux.Provider>
                            </ThemeProvider>
                        </EmotionThemeProvider>
                    </EmotionCacheProvider>
                </I18nProvider>
            </ErrorBoundary>
        </React.StrictMode>
    );
}

export default Providers;
