import {PAYMENT_URL, PAYMENT_URL_NEW, USER_SERVICE_URL} from "@atg-shared/service-url";
import type {AmountOptions} from "@atg-payment-shared/deposit-types";

export const amountOptions: AmountOptions = {
    amounts: [20_000, 50_000, 100_000, 500_000],
    selected: null,
};

export const amountOptionsSmall = (minAmount: number): AmountOptions => ({
    amounts: [minAmount, 15_000, 30_000, 50_000],
    selected: null,
});

export const DepositOptionIds = {
    swishdirect: "swishdirect",
    creditcard: "creditcard",
    trustly: "trustly",
} as const;

export const DepositURLs = {
    DEPOSIT_URL: `${USER_SERVICE_URL}/payin`,
    CREDIT_CARD_URL: `${PAYMENT_URL_NEW}/deposit/${DepositOptionIds.creditcard}`,
    OPTIONS_URL: `${PAYMENT_URL}/deposit/options`,
    SWISH_DIRECT_URL: `${PAYMENT_URL}/deposit/${DepositOptionIds.swishdirect}`,
    TRUSTLY_URL: `${PAYMENT_URL_NEW}/deposit/${DepositOptionIds.trustly}`,
} as const;

export const CardStatuses = {
    VALID: "VALID",
    EXPIRING: "EXPIRING",
    EXPIRED: "EXPIRED",
} as const;

export const DepositStatuses = {
    PAYEX_CHECKOUT: "PAYEX_CHECKOUT",
    PAYEX_DENIED: "DENIED",
    PAYEX_TIMEOUT: "PAYEX_TIMEOUT",
    SWISH_DIRECT_RESET: "SWISH_DIRECT_RESET",
    SWISH_DIRECT_INITIATED: "SWISH_DIRECT_INITIATED",
    SWISH_DIRECT_PENDING: "SWISH_DIRECT_PENDING",
} as const;

export const DepositStorageKeys = {
    depositModalHeader: "depositModalHeader",
    isInDepositFlow: "isInDepositFlow",
    isDepositFlowOnPage: "isDepositFlowOnPage",
    depositWithTrustlyStarted: "depositWithTrustlyStarted",
    depositOrderId: "depositOrderId",
    depositSelectedOption: "depositSelectedOption",
    userDepositPreferences: "userDepositPreferences",
} as const;

export const DepositResponseStatus = {
    INITIATED: "INITIATED",
    COMPLETED: "COMPLETED",
    ERROR: "ERROR",
    IN_PROGRESS: "IN_PROGRESS",
    CANCELLED: "CANCELLED",
    DECLINED: "DECLINED",
    DELAYED: "DELAYED",
    REVERSAL: "REVERSAL",
    BUDGET_EXCEEDED: "BUDGET_EXCEEDED",
    ALREADY_EXIST: "ALREADY_EXIST",
    PAID: "PAID",
} as const;

export type DepositResponseStatusType = keyof typeof DepositResponseStatus;
