import {combineReducers, type Reducer} from "redux";
import {
    ACTION_CONSTANTS,
    VIEW_STATE_CONSTANTS,
    API_CONSTANTS,
} from "@atg-payment/withdraw-types";
import type {StatusMessage, WithdrawalResponseStatus} from "@atg-payment/withdraw-types";
import type {
    WithdrawAction,
    StatusMessageAction,
    SetStatusMessagePayload,
    FetchWithdrawOptionsAction,
} from "../actions/actionTypes";
import type {
    BankAccountState,
    WithdrawOptionsState,
    WithdrawState,
    ViewState,
} from "../domainTypes";

export type StatusMessageState = SetStatusMessagePayload | StatusMessage | null;

type IsLoadingReducer = Reducer<boolean, FetchWithdrawOptionsAction>;
type IsPendingReducer = Reducer<boolean, WithdrawAction>;
type IsBlockedReducer = Reducer<boolean, WithdrawAction>;
type ViewStateReducer = Reducer<ViewState, WithdrawAction>;
type BankAccountReducer = Reducer<BankAccountState, WithdrawAction>;
type StatusMessageReducer = Reducer<StatusMessageState, StatusMessageAction>;
type WithdrawOptionsReducer = Reducer<WithdrawOptionsState, WithdrawAction>;

const isLoading: IsLoadingReducer = (state = true, action) => {
    switch (action.type) {
        case ACTION_CONSTANTS.FETCH_WITHDRAW_OPTIONS_SUCCESS:
        case ACTION_CONSTANTS.FETCH_WITHDRAW_OPTIONS_FAILURE:
            return false;
        default:
            return state;
    }
};

const isPending: IsPendingReducer = (state = false, action) => {
    switch (action.type) {
        case ACTION_CONSTANTS.WITHDRAW_MONEY:
        case ACTION_CONSTANTS.REGISTER_BANK_ACCOUNT:
        case ACTION_CONSTANTS.DELETE_BANK_ACCOUNT:
        case ACTION_CONSTANTS.FETCH_WITHDRAW_OPTIONS:
            return true;
        case ACTION_CONSTANTS.WITHDRAW_MONEY_SUCCESS:
        case ACTION_CONSTANTS.WITHDRAW_MONEY_FAILURE:
        case ACTION_CONSTANTS.WITHDRAW_MONEY_BLOCKED:
        case ACTION_CONSTANTS.REGISTER_BANK_ACCOUNT_SUCCESS:
        case ACTION_CONSTANTS.REGISTER_BANK_ACCOUNT_FAILURE:
        case ACTION_CONSTANTS.DELETE_BANK_ACCOUNT_SUCCESS:
        case ACTION_CONSTANTS.DELETE_BANK_ACCOUNT_FAILURE:
        case ACTION_CONSTANTS.WITHDRAW_OPTIONS:
        case ACTION_CONSTANTS.CLEAR_STATUS_MESSAGES:
        case ACTION_CONSTANTS.SET_STATUS_MESSAGE:
        case ACTION_CONSTANTS.FETCH_WITHDRAW_OPTIONS_FAILURE:
            return false;
        default:
            return state;
    }
};

const isBlocked: IsBlockedReducer = (state = false, action) => {
    switch (action.type) {
        case ACTION_CONSTANTS.WITHDRAW_MONEY_BLOCKED:
        case ACTION_CONSTANTS.FETCH_WITHDRAW_OPTIONS_FAILURE:
            return true;
        default:
            return state;
    }
};

const getViewStateFromResponse = (status: WithdrawalResponseStatus): ViewState => {
    let state: ViewState = VIEW_STATE_CONSTANTS.SUMMARY;
    switch (status) {
        case API_CONSTANTS.IN_PROGRESS:
            state = VIEW_STATE_CONSTANTS.IN_PROGRESS; // loading screen
            break;
        case API_CONSTANTS.ERROR:
        case API_CONSTANTS.UNKNOWN:
        case API_CONSTANTS.REVERSAL:
        case API_CONSTANTS.WALLET_ERROR:
        case API_CONSTANTS.WALLET_REVERSAL_ERROR:
        case API_CONSTANTS.NOT_FOUND:
            state = VIEW_STATE_CONSTANTS.ERROR;
            break;
        case API_CONSTANTS.ERROR_REQUEST_TIMEOUT:
            state = VIEW_STATE_CONSTANTS.ERROR_REQUEST_TIMEOUT;
            break;
        case API_CONSTANTS.ERROR_EWS_TIMEOUT:
            state = VIEW_STATE_CONSTANTS.ERROR_EWS_TIMEOUT;
            break;
        case API_CONSTANTS.ERROR_SERVICE_UNAVAILABLE:
            state = VIEW_STATE_CONSTANTS.ERROR_SERVICE_UNAVAILABLE;
            break;
        case API_CONSTANTS.COMPLETED:
            state = VIEW_STATE_CONSTANTS.SUCCESS;
            break;
        case API_CONSTANTS.DENIED_WITHDRAWAL_LIMIT_REACHED:
            state = VIEW_STATE_CONSTANTS.DENIED_WITHDRAWAL_LIMIT_REACHED;
            break;
        case API_CONSTANTS.INIT:
            state = VIEW_STATE_CONSTANTS.WAITING; // waiting for bank to process withdrawal
            break;
        case API_CONSTANTS.ERROR_SWISH_WRONG_NUMBER:
            state = VIEW_STATE_CONSTANTS.ERROR_SWISH_WRONG_NUMBER;
            break;
        default:
            state = VIEW_STATE_CONSTANTS.SUMMARY;
            break;
    }

    return state;
};

const viewState: ViewStateReducer = (state = VIEW_STATE_CONSTANTS.SUMMARY, action) => {
    switch (action.type) {
        case ACTION_CONSTANTS.WITHDRAW_RESPONSE:
            return getViewStateFromResponse(action.payload);
        case ACTION_CONSTANTS.SET_STATUS_MESSAGE:
            if (action.payload.status === API_CONSTANTS.ERROR) {
                return VIEW_STATE_CONSTANTS.ERROR;
            }
            return state;
        case ACTION_CONSTANTS.REGISTER_BANK_ACCOUNT_SUCCESS:
            return VIEW_STATE_CONSTANTS.SUMMARY;
        case ACTION_CONSTANTS.DELETE_BANK_ACCOUNT_SUCCESS:
            return VIEW_STATE_CONSTANTS.UNREGISTRED;
        case ACTION_CONSTANTS.WITHDRAW_MONEY_SUCCESS:
            return VIEW_STATE_CONSTANTS.SUCCESS;
        case ACTION_CONSTANTS.CLEAR_STATUS_MESSAGES:
            switch (state) {
                case VIEW_STATE_CONSTANTS.SUCCESS:
                case VIEW_STATE_CONSTANTS.ERROR:
                case VIEW_STATE_CONSTANTS.WAITING:
                case VIEW_STATE_CONSTANTS.DENIED_WITHDRAWAL_LIMIT_REACHED:
                case VIEW_STATE_CONSTANTS.ERROR_EWS_TIMEOUT:
                case VIEW_STATE_CONSTANTS.ERROR_SERVICE_UNAVAILABLE:
                case VIEW_STATE_CONSTANTS.ERROR_REQUEST_TIMEOUT:
                case VIEW_STATE_CONSTANTS.ERROR_SWISH_WRONG_NUMBER:
                    return VIEW_STATE_CONSTANTS.SUMMARY;
                default:
                    return state;
            }
        default:
            return state;
    }
};

const bankAccount: BankAccountReducer = (state = null, action) => {
    switch (action.type) {
        case ACTION_CONSTANTS.DELETE_BANK_ACCOUNT_SUCCESS:
            return null;
        case ACTION_CONSTANTS.REGISTER_BANK_ACCOUNT_SUCCESS:
            return action.payload.bankAccount;
        default:
            return state;
    }
};

const statusMessage: StatusMessageReducer = (state = null, action) => {
    switch (action.type) {
        case ACTION_CONSTANTS.SET_STATUS_MESSAGE:
        case ACTION_CONSTANTS.WITHDRAW_MONEY_SUCCESS:
        case ACTION_CONSTANTS.WITHDRAW_MONEY_FAILURE:
        case ACTION_CONSTANTS.REGISTER_BANK_ACCOUNT_FAILURE:
        case ACTION_CONSTANTS.DELETE_BANK_ACCOUNT_FAILURE:
        case ACTION_CONSTANTS.DELETE_BANK_ACCOUNT_SUCCESS:
            return action.payload;
        case ACTION_CONSTANTS.REGISTER_BANK_ACCOUNT_SUCCESS:
            return action.payload.message;
        case ACTION_CONSTANTS.WITHDRAW_MONEY:
        case ACTION_CONSTANTS.REGISTER_BANK_ACCOUNT:
        case ACTION_CONSTANTS.CLEAR_STATUS_MESSAGES:
            return null;
        default:
            return state;
    }
};

const withdrawOptions: WithdrawOptionsReducer = (state = null, action) => {
    switch (action.type) {
        case ACTION_CONSTANTS.WITHDRAW_OPTIONS:
            return action.payload;
        case ACTION_CONSTANTS.DELETE_BANK_ACCOUNT_SUCCESS:
            if (!state) return null;

            return {...state, bankAccount: undefined};
        default:
            return state;
    }
};

const withdrawReducer: Reducer<WithdrawState, WithdrawAction> = combineReducers({
    isLoading,
    isPending,
    isBlocked,
    viewState,
    bankAccount,
    statusMessage,
    withdrawOptions,
});

export default withdrawReducer;
