import * as React from "react";
import {ComponentLoader} from "@atg-shared/lazy";
import {ResponsibleGamingHeader} from "@atg-responsible-gambling-shared/header-feature";
import {LoadingIndicator} from "atg-ui-components";

export function LazyFramtidensButik() {
    return (
        <>
            <ResponsibleGamingHeader />
            <ComponentLoader
                loader={import(
                    /* webpackChunkName: "FramtidensButik" */
                    "./LandingPage"
                )}
                placeholder={<LoadingIndicator />}
            />
        </>
    );
}
